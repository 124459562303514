/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import * as haKodeproV1001 from '../../../ha/kodepro/v1/user-mgmt.pb';
import * as haKodeproV1002 from '../../../ha/kodepro/v1/ws-monitoring.pb';
import * as haKodeproV1003 from '../../../ha/kodepro/v1/common.pb';
import * as haKodeproV1004 from '../../../ha/kodepro/v1/ws-template.pb';
export enum WorkspaceDataFilter {
  UNSPECIFIED = 0,
  TEMPLATE = 1,
  OPTIONS = 2,
  ACCESS_CONTROL = 3,
  MONITORING = 4
}
/**
 * Message implementation for ha.kodepro.v1.WorkspaceRequest
 */
export class WorkspaceRequest implements GrpcMessage {
  static id = 'ha.kodepro.v1.WorkspaceRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new WorkspaceRequest();
    WorkspaceRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: WorkspaceRequest) {
    _instance.id = _instance.id || '';
    _instance.overrideOptions = _instance.overrideOptions || undefined;
    _instance.dataFilters = _instance.dataFilters || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: WorkspaceRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 2:
          _instance.overrideOptions = new haKodeproV1004.WorkspaceOptions();
          _reader.readMessage(
            _instance.overrideOptions,
            haKodeproV1004.WorkspaceOptions.deserializeBinaryFromReader
          );
          break;
        case 3:
          (_instance.dataFilters = _instance.dataFilters || []).push(
            ...(_reader.readPackedEnum() || [])
          );
          break;
        default:
          _reader.skipField();
      }
    }

    WorkspaceRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: WorkspaceRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.overrideOptions) {
      _writer.writeMessage(
        2,
        _instance.overrideOptions as any,
        haKodeproV1004.WorkspaceOptions.serializeBinaryToWriter
      );
    }
    if (_instance.dataFilters && _instance.dataFilters.length) {
      _writer.writePackedEnum(3, _instance.dataFilters);
    }
  }

  private _id: string;
  private _overrideOptions?: haKodeproV1004.WorkspaceOptions;
  private _dataFilters: WorkspaceDataFilter[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of WorkspaceRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<WorkspaceRequest.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.overrideOptions = _value.overrideOptions
      ? new haKodeproV1004.WorkspaceOptions(_value.overrideOptions)
      : undefined;
    this.dataFilters = (_value.dataFilters || []).slice();
    WorkspaceRequest.refineValues(this);
  }
  get id(): string {
    return this._id;
  }
  set id(value: string) {
    this._id = value;
  }
  get overrideOptions(): haKodeproV1004.WorkspaceOptions | undefined {
    return this._overrideOptions;
  }
  set overrideOptions(value: haKodeproV1004.WorkspaceOptions | undefined) {
    this._overrideOptions = value;
  }
  get dataFilters(): WorkspaceDataFilter[] {
    return this._dataFilters;
  }
  set dataFilters(value: WorkspaceDataFilter[]) {
    this._dataFilters = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    WorkspaceRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): WorkspaceRequest.AsObject {
    return {
      id: this.id,
      overrideOptions: this.overrideOptions
        ? this.overrideOptions.toObject()
        : undefined,
      dataFilters: (this.dataFilters || []).slice()
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): WorkspaceRequest.AsProtobufJSON {
    return {
      id: this.id,
      overrideOptions: this.overrideOptions
        ? this.overrideOptions.toProtobufJSON(options)
        : null,
      dataFilters: (this.dataFilters || []).map(v => WorkspaceDataFilter[v])
    };
  }
}
export module WorkspaceRequest {
  /**
   * Standard JavaScript object representation for WorkspaceRequest
   */
  export interface AsObject {
    id: string;
    overrideOptions?: haKodeproV1004.WorkspaceOptions.AsObject;
    dataFilters: WorkspaceDataFilter[];
  }

  /**
   * Protobuf JSON representation for WorkspaceRequest
   */
  export interface AsProtobufJSON {
    id: string;
    overrideOptions: haKodeproV1004.WorkspaceOptions.AsProtobufJSON | null;
    dataFilters: string[];
  }
}

/**
 * Message implementation for ha.kodepro.v1.StopWorkspaceRequest
 */
export class StopWorkspaceRequest implements GrpcMessage {
  static id = 'ha.kodepro.v1.StopWorkspaceRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new StopWorkspaceRequest();
    StopWorkspaceRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: StopWorkspaceRequest) {
    _instance.id = _instance.id || '';
    _instance.backup = _instance.backup || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: StopWorkspaceRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 2:
          _instance.backup = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    StopWorkspaceRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: StopWorkspaceRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.backup) {
      _writer.writeBool(2, _instance.backup);
    }
  }

  private _id: string;
  private _backup: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of StopWorkspaceRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<StopWorkspaceRequest.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.backup = _value.backup;
    StopWorkspaceRequest.refineValues(this);
  }
  get id(): string {
    return this._id;
  }
  set id(value: string) {
    this._id = value;
  }
  get backup(): boolean {
    return this._backup;
  }
  set backup(value: boolean) {
    this._backup = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    StopWorkspaceRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): StopWorkspaceRequest.AsObject {
    return {
      id: this.id,
      backup: this.backup
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): StopWorkspaceRequest.AsProtobufJSON {
    return {
      id: this.id,
      backup: this.backup
    };
  }
}
export module StopWorkspaceRequest {
  /**
   * Standard JavaScript object representation for StopWorkspaceRequest
   */
  export interface AsObject {
    id: string;
    backup: boolean;
  }

  /**
   * Protobuf JSON representation for StopWorkspaceRequest
   */
  export interface AsProtobufJSON {
    id: string;
    backup: boolean;
  }
}

/**
 * Message implementation for ha.kodepro.v1.TemporaryWorkspaceRequest
 */
export class TemporaryWorkspaceRequest implements GrpcMessage {
  static id = 'ha.kodepro.v1.TemporaryWorkspaceRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new TemporaryWorkspaceRequest();
    TemporaryWorkspaceRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: TemporaryWorkspaceRequest) {
    _instance.template = _instance.template || undefined;
    _instance.overrideOptions = _instance.overrideOptions || undefined;
    _instance.restoreWorkspaceId = _instance.restoreWorkspaceId || '';
    _instance.restoreSnapshotId = _instance.restoreSnapshotId || '';
    _instance.tenantCode = _instance.tenantCode || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: TemporaryWorkspaceRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.template = new haKodeproV1004.WorkspaceTemplate();
          _reader.readMessage(
            _instance.template,
            haKodeproV1004.WorkspaceTemplate.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.overrideOptions = new haKodeproV1004.WorkspaceOptions();
          _reader.readMessage(
            _instance.overrideOptions,
            haKodeproV1004.WorkspaceOptions.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.restoreWorkspaceId = _reader.readString();
          break;
        case 4:
          _instance.restoreSnapshotId = _reader.readString();
          break;
        case 5:
          _instance.tenantCode = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    TemporaryWorkspaceRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: TemporaryWorkspaceRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.template) {
      _writer.writeMessage(
        1,
        _instance.template as any,
        haKodeproV1004.WorkspaceTemplate.serializeBinaryToWriter
      );
    }
    if (_instance.overrideOptions) {
      _writer.writeMessage(
        2,
        _instance.overrideOptions as any,
        haKodeproV1004.WorkspaceOptions.serializeBinaryToWriter
      );
    }
    if (_instance.restoreWorkspaceId) {
      _writer.writeString(3, _instance.restoreWorkspaceId);
    }
    if (_instance.restoreSnapshotId) {
      _writer.writeString(4, _instance.restoreSnapshotId);
    }
    if (_instance.tenantCode) {
      _writer.writeString(5, _instance.tenantCode);
    }
  }

  private _template?: haKodeproV1004.WorkspaceTemplate;
  private _overrideOptions?: haKodeproV1004.WorkspaceOptions;
  private _restoreWorkspaceId: string;
  private _restoreSnapshotId: string;
  private _tenantCode: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of TemporaryWorkspaceRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<TemporaryWorkspaceRequest.AsObject>) {
    _value = _value || {};
    this.template = _value.template
      ? new haKodeproV1004.WorkspaceTemplate(_value.template)
      : undefined;
    this.overrideOptions = _value.overrideOptions
      ? new haKodeproV1004.WorkspaceOptions(_value.overrideOptions)
      : undefined;
    this.restoreWorkspaceId = _value.restoreWorkspaceId;
    this.restoreSnapshotId = _value.restoreSnapshotId;
    this.tenantCode = _value.tenantCode;
    TemporaryWorkspaceRequest.refineValues(this);
  }
  get template(): haKodeproV1004.WorkspaceTemplate | undefined {
    return this._template;
  }
  set template(value: haKodeproV1004.WorkspaceTemplate | undefined) {
    this._template = value;
  }
  get overrideOptions(): haKodeproV1004.WorkspaceOptions | undefined {
    return this._overrideOptions;
  }
  set overrideOptions(value: haKodeproV1004.WorkspaceOptions | undefined) {
    this._overrideOptions = value;
  }
  get restoreWorkspaceId(): string {
    return this._restoreWorkspaceId;
  }
  set restoreWorkspaceId(value: string) {
    this._restoreWorkspaceId = value;
  }
  get restoreSnapshotId(): string {
    return this._restoreSnapshotId;
  }
  set restoreSnapshotId(value: string) {
    this._restoreSnapshotId = value;
  }
  get tenantCode(): string {
    return this._tenantCode;
  }
  set tenantCode(value: string) {
    this._tenantCode = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    TemporaryWorkspaceRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): TemporaryWorkspaceRequest.AsObject {
    return {
      template: this.template ? this.template.toObject() : undefined,
      overrideOptions: this.overrideOptions
        ? this.overrideOptions.toObject()
        : undefined,
      restoreWorkspaceId: this.restoreWorkspaceId,
      restoreSnapshotId: this.restoreSnapshotId,
      tenantCode: this.tenantCode
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): TemporaryWorkspaceRequest.AsProtobufJSON {
    return {
      template: this.template ? this.template.toProtobufJSON(options) : null,
      overrideOptions: this.overrideOptions
        ? this.overrideOptions.toProtobufJSON(options)
        : null,
      restoreWorkspaceId: this.restoreWorkspaceId,
      restoreSnapshotId: this.restoreSnapshotId,
      tenantCode: this.tenantCode
    };
  }
}
export module TemporaryWorkspaceRequest {
  /**
   * Standard JavaScript object representation for TemporaryWorkspaceRequest
   */
  export interface AsObject {
    template?: haKodeproV1004.WorkspaceTemplate.AsObject;
    overrideOptions?: haKodeproV1004.WorkspaceOptions.AsObject;
    restoreWorkspaceId: string;
    restoreSnapshotId: string;
    tenantCode: string;
  }

  /**
   * Protobuf JSON representation for TemporaryWorkspaceRequest
   */
  export interface AsProtobufJSON {
    template: haKodeproV1004.WorkspaceTemplate.AsProtobufJSON | null;
    overrideOptions: haKodeproV1004.WorkspaceOptions.AsProtobufJSON | null;
    restoreWorkspaceId: string;
    restoreSnapshotId: string;
    tenantCode: string;
  }
}

/**
 * Message implementation for ha.kodepro.v1.CommandRequest
 */
export class CommandRequest implements GrpcMessage {
  static id = 'ha.kodepro.v1.CommandRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CommandRequest();
    CommandRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CommandRequest) {
    _instance.workspaceId = _instance.workspaceId || '';
    _instance.exec = _instance.exec || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CommandRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.workspaceId = _reader.readString();
          break;
        case 2:
          _instance.exec = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    CommandRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CommandRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.workspaceId) {
      _writer.writeString(1, _instance.workspaceId);
    }
    if (_instance.exec) {
      _writer.writeString(2, _instance.exec);
    }
  }

  private _workspaceId: string;
  private _exec: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CommandRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<CommandRequest.AsObject>) {
    _value = _value || {};
    this.workspaceId = _value.workspaceId;
    this.exec = _value.exec;
    CommandRequest.refineValues(this);
  }
  get workspaceId(): string {
    return this._workspaceId;
  }
  set workspaceId(value: string) {
    this._workspaceId = value;
  }
  get exec(): string {
    return this._exec;
  }
  set exec(value: string) {
    this._exec = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CommandRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CommandRequest.AsObject {
    return {
      workspaceId: this.workspaceId,
      exec: this.exec
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CommandRequest.AsProtobufJSON {
    return {
      workspaceId: this.workspaceId,
      exec: this.exec
    };
  }
}
export module CommandRequest {
  /**
   * Standard JavaScript object representation for CommandRequest
   */
  export interface AsObject {
    workspaceId: string;
    exec: string;
  }

  /**
   * Protobuf JSON representation for CommandRequest
   */
  export interface AsProtobufJSON {
    workspaceId: string;
    exec: string;
  }
}

/**
 * Message implementation for ha.kodepro.v1.TriggerJobRequest
 */
export class TriggerJobRequest implements GrpcMessage {
  static id = 'ha.kodepro.v1.TriggerJobRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new TriggerJobRequest();
    TriggerJobRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: TriggerJobRequest) {
    _instance.jobId = _instance.jobId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: TriggerJobRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.jobId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    TriggerJobRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: TriggerJobRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.jobId) {
      _writer.writeString(1, _instance.jobId);
    }
  }

  private _jobId: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of TriggerJobRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<TriggerJobRequest.AsObject>) {
    _value = _value || {};
    this.jobId = _value.jobId;
    TriggerJobRequest.refineValues(this);
  }
  get jobId(): string {
    return this._jobId;
  }
  set jobId(value: string) {
    this._jobId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    TriggerJobRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): TriggerJobRequest.AsObject {
    return {
      jobId: this.jobId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): TriggerJobRequest.AsProtobufJSON {
    return {
      jobId: this.jobId
    };
  }
}
export module TriggerJobRequest {
  /**
   * Standard JavaScript object representation for TriggerJobRequest
   */
  export interface AsObject {
    jobId: string;
  }

  /**
   * Protobuf JSON representation for TriggerJobRequest
   */
  export interface AsProtobufJSON {
    jobId: string;
  }
}

/**
 * Message implementation for ha.kodepro.v1.WorkspaceListRequest
 */
export class WorkspaceListRequest implements GrpcMessage {
  static id = 'ha.kodepro.v1.WorkspaceListRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new WorkspaceListRequest();
    WorkspaceListRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: WorkspaceListRequest) {
    _instance.status = _instance.status || '';
    _instance.pagination = _instance.pagination || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: WorkspaceListRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.status = _reader.readString();
          break;
        case 2:
          _instance.pagination = new haKodeproV1003.PaginationRequest();
          _reader.readMessage(
            _instance.pagination,
            haKodeproV1003.PaginationRequest.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    WorkspaceListRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: WorkspaceListRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.status) {
      _writer.writeString(1, _instance.status);
    }
    if (_instance.pagination) {
      _writer.writeMessage(
        2,
        _instance.pagination as any,
        haKodeproV1003.PaginationRequest.serializeBinaryToWriter
      );
    }
  }

  private _status: string;
  private _pagination?: haKodeproV1003.PaginationRequest;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of WorkspaceListRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<WorkspaceListRequest.AsObject>) {
    _value = _value || {};
    this.status = _value.status;
    this.pagination = _value.pagination
      ? new haKodeproV1003.PaginationRequest(_value.pagination)
      : undefined;
    WorkspaceListRequest.refineValues(this);
  }
  get status(): string {
    return this._status;
  }
  set status(value: string) {
    this._status = value;
  }
  get pagination(): haKodeproV1003.PaginationRequest | undefined {
    return this._pagination;
  }
  set pagination(value: haKodeproV1003.PaginationRequest | undefined) {
    this._pagination = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    WorkspaceListRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): WorkspaceListRequest.AsObject {
    return {
      status: this.status,
      pagination: this.pagination ? this.pagination.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): WorkspaceListRequest.AsProtobufJSON {
    return {
      status: this.status,
      pagination: this.pagination
        ? this.pagination.toProtobufJSON(options)
        : null
    };
  }
}
export module WorkspaceListRequest {
  /**
   * Standard JavaScript object representation for WorkspaceListRequest
   */
  export interface AsObject {
    status: string;
    pagination?: haKodeproV1003.PaginationRequest.AsObject;
  }

  /**
   * Protobuf JSON representation for WorkspaceListRequest
   */
  export interface AsProtobufJSON {
    status: string;
    pagination: haKodeproV1003.PaginationRequest.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for ha.kodepro.v1.AuthTokenRequest
 */
export class AuthTokenRequest implements GrpcMessage {
  static id = 'ha.kodepro.v1.AuthTokenRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AuthTokenRequest();
    AuthTokenRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AuthTokenRequest) {
    _instance.username = _instance.username || '';
    _instance.password = _instance.password || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AuthTokenRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.username = _reader.readString();
          break;
        case 2:
          _instance.password = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    AuthTokenRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AuthTokenRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.username) {
      _writer.writeString(1, _instance.username);
    }
    if (_instance.password) {
      _writer.writeString(2, _instance.password);
    }
  }

  private _username: string;
  private _password: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AuthTokenRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<AuthTokenRequest.AsObject>) {
    _value = _value || {};
    this.username = _value.username;
    this.password = _value.password;
    AuthTokenRequest.refineValues(this);
  }
  get username(): string {
    return this._username;
  }
  set username(value: string) {
    this._username = value;
  }
  get password(): string {
    return this._password;
  }
  set password(value: string) {
    this._password = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AuthTokenRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AuthTokenRequest.AsObject {
    return {
      username: this.username,
      password: this.password
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AuthTokenRequest.AsProtobufJSON {
    return {
      username: this.username,
      password: this.password
    };
  }
}
export module AuthTokenRequest {
  /**
   * Standard JavaScript object representation for AuthTokenRequest
   */
  export interface AsObject {
    username: string;
    password: string;
  }

  /**
   * Protobuf JSON representation for AuthTokenRequest
   */
  export interface AsProtobufJSON {
    username: string;
    password: string;
  }
}

/**
 * Message implementation for ha.kodepro.v1.WorkspaceCreateRequest
 */
export class WorkspaceCreateRequest implements GrpcMessage {
  static id = 'ha.kodepro.v1.WorkspaceCreateRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new WorkspaceCreateRequest();
    WorkspaceCreateRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: WorkspaceCreateRequest) {
    _instance.startWorkspace = _instance.startWorkspace || false;
    _instance.template = _instance.template || undefined;
    _instance.options = _instance.options || undefined;
    _instance.accessControl = _instance.accessControl || undefined;
    _instance.tenantCode = _instance.tenantCode || '';
    _instance.startDate = _instance.startDate || undefined;
    _instance.endDate = _instance.endDate || undefined;
    _instance.userGroupId = _instance.userGroupId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: WorkspaceCreateRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.startWorkspace = _reader.readBool();
          break;
        case 2:
          _instance.template = new haKodeproV1004.WorkspaceTemplate();
          _reader.readMessage(
            _instance.template,
            haKodeproV1004.WorkspaceTemplate.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.options = new haKodeproV1004.WorkspaceOptions();
          _reader.readMessage(
            _instance.options,
            haKodeproV1004.WorkspaceOptions.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.accessControl = new haKodeproV1001.AccessControl();
          _reader.readMessage(
            _instance.accessControl,
            haKodeproV1001.AccessControl.deserializeBinaryFromReader
          );
          break;
        case 5:
          _instance.tenantCode = _reader.readString();
          break;
        case 6:
          _instance.startDate = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.startDate,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 7:
          _instance.endDate = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.endDate,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 8:
          _instance.userGroupId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    WorkspaceCreateRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: WorkspaceCreateRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.startWorkspace) {
      _writer.writeBool(1, _instance.startWorkspace);
    }
    if (_instance.template) {
      _writer.writeMessage(
        2,
        _instance.template as any,
        haKodeproV1004.WorkspaceTemplate.serializeBinaryToWriter
      );
    }
    if (_instance.options) {
      _writer.writeMessage(
        3,
        _instance.options as any,
        haKodeproV1004.WorkspaceOptions.serializeBinaryToWriter
      );
    }
    if (_instance.accessControl) {
      _writer.writeMessage(
        4,
        _instance.accessControl as any,
        haKodeproV1001.AccessControl.serializeBinaryToWriter
      );
    }
    if (_instance.tenantCode) {
      _writer.writeString(5, _instance.tenantCode);
    }
    if (_instance.startDate) {
      _writer.writeMessage(
        6,
        _instance.startDate as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.endDate) {
      _writer.writeMessage(
        7,
        _instance.endDate as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.userGroupId) {
      _writer.writeString(8, _instance.userGroupId);
    }
  }

  private _startWorkspace: boolean;
  private _template?: haKodeproV1004.WorkspaceTemplate;
  private _options?: haKodeproV1004.WorkspaceOptions;
  private _accessControl?: haKodeproV1001.AccessControl;
  private _tenantCode: string;
  private _startDate?: googleProtobuf000.Timestamp;
  private _endDate?: googleProtobuf000.Timestamp;
  private _userGroupId: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of WorkspaceCreateRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<WorkspaceCreateRequest.AsObject>) {
    _value = _value || {};
    this.startWorkspace = _value.startWorkspace;
    this.template = _value.template
      ? new haKodeproV1004.WorkspaceTemplate(_value.template)
      : undefined;
    this.options = _value.options
      ? new haKodeproV1004.WorkspaceOptions(_value.options)
      : undefined;
    this.accessControl = _value.accessControl
      ? new haKodeproV1001.AccessControl(_value.accessControl)
      : undefined;
    this.tenantCode = _value.tenantCode;
    this.startDate = _value.startDate
      ? new googleProtobuf000.Timestamp(_value.startDate)
      : undefined;
    this.endDate = _value.endDate
      ? new googleProtobuf000.Timestamp(_value.endDate)
      : undefined;
    this.userGroupId = _value.userGroupId;
    WorkspaceCreateRequest.refineValues(this);
  }
  get startWorkspace(): boolean {
    return this._startWorkspace;
  }
  set startWorkspace(value: boolean) {
    this._startWorkspace = value;
  }
  get template(): haKodeproV1004.WorkspaceTemplate | undefined {
    return this._template;
  }
  set template(value: haKodeproV1004.WorkspaceTemplate | undefined) {
    this._template = value;
  }
  get options(): haKodeproV1004.WorkspaceOptions | undefined {
    return this._options;
  }
  set options(value: haKodeproV1004.WorkspaceOptions | undefined) {
    this._options = value;
  }
  get accessControl(): haKodeproV1001.AccessControl | undefined {
    return this._accessControl;
  }
  set accessControl(value: haKodeproV1001.AccessControl | undefined) {
    this._accessControl = value;
  }
  get tenantCode(): string {
    return this._tenantCode;
  }
  set tenantCode(value: string) {
    this._tenantCode = value;
  }
  get startDate(): googleProtobuf000.Timestamp | undefined {
    return this._startDate;
  }
  set startDate(value: googleProtobuf000.Timestamp | undefined) {
    this._startDate = value;
  }
  get endDate(): googleProtobuf000.Timestamp | undefined {
    return this._endDate;
  }
  set endDate(value: googleProtobuf000.Timestamp | undefined) {
    this._endDate = value;
  }
  get userGroupId(): string {
    return this._userGroupId;
  }
  set userGroupId(value: string) {
    this._userGroupId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    WorkspaceCreateRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): WorkspaceCreateRequest.AsObject {
    return {
      startWorkspace: this.startWorkspace,
      template: this.template ? this.template.toObject() : undefined,
      options: this.options ? this.options.toObject() : undefined,
      accessControl: this.accessControl
        ? this.accessControl.toObject()
        : undefined,
      tenantCode: this.tenantCode,
      startDate: this.startDate ? this.startDate.toObject() : undefined,
      endDate: this.endDate ? this.endDate.toObject() : undefined,
      userGroupId: this.userGroupId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): WorkspaceCreateRequest.AsProtobufJSON {
    return {
      startWorkspace: this.startWorkspace,
      template: this.template ? this.template.toProtobufJSON(options) : null,
      options: this.options ? this.options.toProtobufJSON(options) : null,
      accessControl: this.accessControl
        ? this.accessControl.toProtobufJSON(options)
        : null,
      tenantCode: this.tenantCode,
      startDate: this.startDate ? this.startDate.toProtobufJSON(options) : null,
      endDate: this.endDate ? this.endDate.toProtobufJSON(options) : null,
      userGroupId: this.userGroupId
    };
  }
}
export module WorkspaceCreateRequest {
  /**
   * Standard JavaScript object representation for WorkspaceCreateRequest
   */
  export interface AsObject {
    startWorkspace: boolean;
    template?: haKodeproV1004.WorkspaceTemplate.AsObject;
    options?: haKodeproV1004.WorkspaceOptions.AsObject;
    accessControl?: haKodeproV1001.AccessControl.AsObject;
    tenantCode: string;
    startDate?: googleProtobuf000.Timestamp.AsObject;
    endDate?: googleProtobuf000.Timestamp.AsObject;
    userGroupId: string;
  }

  /**
   * Protobuf JSON representation for WorkspaceCreateRequest
   */
  export interface AsProtobufJSON {
    startWorkspace: boolean;
    template: haKodeproV1004.WorkspaceTemplate.AsProtobufJSON | null;
    options: haKodeproV1004.WorkspaceOptions.AsProtobufJSON | null;
    accessControl: haKodeproV1001.AccessControl.AsProtobufJSON | null;
    tenantCode: string;
    startDate: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    endDate: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    userGroupId: string;
  }
}

/**
 * Message implementation for ha.kodepro.v1.WorkspaceUpdateRequest
 */
export class WorkspaceUpdateRequest implements GrpcMessage {
  static id = 'ha.kodepro.v1.WorkspaceUpdateRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new WorkspaceUpdateRequest();
    WorkspaceUpdateRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: WorkspaceUpdateRequest) {
    _instance.id = _instance.id || '';
    _instance.operations = _instance.operations || [];
    _instance.template = _instance.template || undefined;
    _instance.options = _instance.options || undefined;
    _instance.accessControl = _instance.accessControl || undefined;
    _instance.startDate = _instance.startDate || undefined;
    _instance.endDate = _instance.endDate || undefined;
    _instance.userGroupId = _instance.userGroupId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: WorkspaceUpdateRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 2:
          (_instance.operations = _instance.operations || []).push(
            ...(_reader.readPackedEnum() || [])
          );
          break;
        case 3:
          _instance.template = new haKodeproV1004.WorkspaceTemplate();
          _reader.readMessage(
            _instance.template,
            haKodeproV1004.WorkspaceTemplate.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.options = new haKodeproV1004.WorkspaceOptions();
          _reader.readMessage(
            _instance.options,
            haKodeproV1004.WorkspaceOptions.deserializeBinaryFromReader
          );
          break;
        case 5:
          _instance.accessControl = new haKodeproV1001.AccessControl();
          _reader.readMessage(
            _instance.accessControl,
            haKodeproV1001.AccessControl.deserializeBinaryFromReader
          );
          break;
        case 6:
          _instance.startDate = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.startDate,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 7:
          _instance.endDate = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.endDate,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 8:
          _instance.userGroupId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    WorkspaceUpdateRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: WorkspaceUpdateRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.operations && _instance.operations.length) {
      _writer.writePackedEnum(2, _instance.operations);
    }
    if (_instance.template) {
      _writer.writeMessage(
        3,
        _instance.template as any,
        haKodeproV1004.WorkspaceTemplate.serializeBinaryToWriter
      );
    }
    if (_instance.options) {
      _writer.writeMessage(
        4,
        _instance.options as any,
        haKodeproV1004.WorkspaceOptions.serializeBinaryToWriter
      );
    }
    if (_instance.accessControl) {
      _writer.writeMessage(
        5,
        _instance.accessControl as any,
        haKodeproV1001.AccessControl.serializeBinaryToWriter
      );
    }
    if (_instance.startDate) {
      _writer.writeMessage(
        6,
        _instance.startDate as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.endDate) {
      _writer.writeMessage(
        7,
        _instance.endDate as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.userGroupId) {
      _writer.writeString(8, _instance.userGroupId);
    }
  }

  private _id: string;
  private _operations: WorkspaceDataFilter[];
  private _template?: haKodeproV1004.WorkspaceTemplate;
  private _options?: haKodeproV1004.WorkspaceOptions;
  private _accessControl?: haKodeproV1001.AccessControl;
  private _startDate?: googleProtobuf000.Timestamp;
  private _endDate?: googleProtobuf000.Timestamp;
  private _userGroupId: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of WorkspaceUpdateRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<WorkspaceUpdateRequest.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.operations = (_value.operations || []).slice();
    this.template = _value.template
      ? new haKodeproV1004.WorkspaceTemplate(_value.template)
      : undefined;
    this.options = _value.options
      ? new haKodeproV1004.WorkspaceOptions(_value.options)
      : undefined;
    this.accessControl = _value.accessControl
      ? new haKodeproV1001.AccessControl(_value.accessControl)
      : undefined;
    this.startDate = _value.startDate
      ? new googleProtobuf000.Timestamp(_value.startDate)
      : undefined;
    this.endDate = _value.endDate
      ? new googleProtobuf000.Timestamp(_value.endDate)
      : undefined;
    this.userGroupId = _value.userGroupId;
    WorkspaceUpdateRequest.refineValues(this);
  }
  get id(): string {
    return this._id;
  }
  set id(value: string) {
    this._id = value;
  }
  get operations(): WorkspaceDataFilter[] {
    return this._operations;
  }
  set operations(value: WorkspaceDataFilter[]) {
    this._operations = value;
  }
  get template(): haKodeproV1004.WorkspaceTemplate | undefined {
    return this._template;
  }
  set template(value: haKodeproV1004.WorkspaceTemplate | undefined) {
    this._template = value;
  }
  get options(): haKodeproV1004.WorkspaceOptions | undefined {
    return this._options;
  }
  set options(value: haKodeproV1004.WorkspaceOptions | undefined) {
    this._options = value;
  }
  get accessControl(): haKodeproV1001.AccessControl | undefined {
    return this._accessControl;
  }
  set accessControl(value: haKodeproV1001.AccessControl | undefined) {
    this._accessControl = value;
  }
  get startDate(): googleProtobuf000.Timestamp | undefined {
    return this._startDate;
  }
  set startDate(value: googleProtobuf000.Timestamp | undefined) {
    this._startDate = value;
  }
  get endDate(): googleProtobuf000.Timestamp | undefined {
    return this._endDate;
  }
  set endDate(value: googleProtobuf000.Timestamp | undefined) {
    this._endDate = value;
  }
  get userGroupId(): string {
    return this._userGroupId;
  }
  set userGroupId(value: string) {
    this._userGroupId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    WorkspaceUpdateRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): WorkspaceUpdateRequest.AsObject {
    return {
      id: this.id,
      operations: (this.operations || []).slice(),
      template: this.template ? this.template.toObject() : undefined,
      options: this.options ? this.options.toObject() : undefined,
      accessControl: this.accessControl
        ? this.accessControl.toObject()
        : undefined,
      startDate: this.startDate ? this.startDate.toObject() : undefined,
      endDate: this.endDate ? this.endDate.toObject() : undefined,
      userGroupId: this.userGroupId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): WorkspaceUpdateRequest.AsProtobufJSON {
    return {
      id: this.id,
      operations: (this.operations || []).map(v => WorkspaceDataFilter[v]),
      template: this.template ? this.template.toProtobufJSON(options) : null,
      options: this.options ? this.options.toProtobufJSON(options) : null,
      accessControl: this.accessControl
        ? this.accessControl.toProtobufJSON(options)
        : null,
      startDate: this.startDate ? this.startDate.toProtobufJSON(options) : null,
      endDate: this.endDate ? this.endDate.toProtobufJSON(options) : null,
      userGroupId: this.userGroupId
    };
  }
}
export module WorkspaceUpdateRequest {
  /**
   * Standard JavaScript object representation for WorkspaceUpdateRequest
   */
  export interface AsObject {
    id: string;
    operations: WorkspaceDataFilter[];
    template?: haKodeproV1004.WorkspaceTemplate.AsObject;
    options?: haKodeproV1004.WorkspaceOptions.AsObject;
    accessControl?: haKodeproV1001.AccessControl.AsObject;
    startDate?: googleProtobuf000.Timestamp.AsObject;
    endDate?: googleProtobuf000.Timestamp.AsObject;
    userGroupId: string;
  }

  /**
   * Protobuf JSON representation for WorkspaceUpdateRequest
   */
  export interface AsProtobufJSON {
    id: string;
    operations: string[];
    template: haKodeproV1004.WorkspaceTemplate.AsProtobufJSON | null;
    options: haKodeproV1004.WorkspaceOptions.AsProtobufJSON | null;
    accessControl: haKodeproV1001.AccessControl.AsProtobufJSON | null;
    startDate: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    endDate: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    userGroupId: string;
  }
}

/**
 * Message implementation for ha.kodepro.v1.WorkspacePatchRequest
 */
export class WorkspacePatchRequest implements GrpcMessage {
  static id = 'ha.kodepro.v1.WorkspacePatchRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new WorkspacePatchRequest();
    WorkspacePatchRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: WorkspacePatchRequest) {
    _instance.id = _instance.id || '';
    _instance.operations = _instance.operations || [];
    _instance.template = _instance.template || undefined;
    _instance.options = _instance.options || undefined;
    _instance.accessControl = _instance.accessControl || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: WorkspacePatchRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 2:
          (_instance.operations = _instance.operations || []).push(
            ...(_reader.readPackedEnum() || [])
          );
          break;
        case 3:
          _instance.template = new haKodeproV1004.WorkspaceTemplate();
          _reader.readMessage(
            _instance.template,
            haKodeproV1004.WorkspaceTemplate.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.options = new haKodeproV1004.WorkspaceOptions();
          _reader.readMessage(
            _instance.options,
            haKodeproV1004.WorkspaceOptions.deserializeBinaryFromReader
          );
          break;
        case 5:
          _instance.accessControl = new haKodeproV1001.AccessControl();
          _reader.readMessage(
            _instance.accessControl,
            haKodeproV1001.AccessControl.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    WorkspacePatchRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: WorkspacePatchRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.operations && _instance.operations.length) {
      _writer.writePackedEnum(2, _instance.operations);
    }
    if (_instance.template) {
      _writer.writeMessage(
        3,
        _instance.template as any,
        haKodeproV1004.WorkspaceTemplate.serializeBinaryToWriter
      );
    }
    if (_instance.options) {
      _writer.writeMessage(
        4,
        _instance.options as any,
        haKodeproV1004.WorkspaceOptions.serializeBinaryToWriter
      );
    }
    if (_instance.accessControl) {
      _writer.writeMessage(
        5,
        _instance.accessControl as any,
        haKodeproV1001.AccessControl.serializeBinaryToWriter
      );
    }
  }

  private _id: string;
  private _operations: WorkspaceDataFilter[];
  private _template?: haKodeproV1004.WorkspaceTemplate;
  private _options?: haKodeproV1004.WorkspaceOptions;
  private _accessControl?: haKodeproV1001.AccessControl;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of WorkspacePatchRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<WorkspacePatchRequest.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.operations = (_value.operations || []).slice();
    this.template = _value.template
      ? new haKodeproV1004.WorkspaceTemplate(_value.template)
      : undefined;
    this.options = _value.options
      ? new haKodeproV1004.WorkspaceOptions(_value.options)
      : undefined;
    this.accessControl = _value.accessControl
      ? new haKodeproV1001.AccessControl(_value.accessControl)
      : undefined;
    WorkspacePatchRequest.refineValues(this);
  }
  get id(): string {
    return this._id;
  }
  set id(value: string) {
    this._id = value;
  }
  get operations(): WorkspaceDataFilter[] {
    return this._operations;
  }
  set operations(value: WorkspaceDataFilter[]) {
    this._operations = value;
  }
  get template(): haKodeproV1004.WorkspaceTemplate | undefined {
    return this._template;
  }
  set template(value: haKodeproV1004.WorkspaceTemplate | undefined) {
    this._template = value;
  }
  get options(): haKodeproV1004.WorkspaceOptions | undefined {
    return this._options;
  }
  set options(value: haKodeproV1004.WorkspaceOptions | undefined) {
    this._options = value;
  }
  get accessControl(): haKodeproV1001.AccessControl | undefined {
    return this._accessControl;
  }
  set accessControl(value: haKodeproV1001.AccessControl | undefined) {
    this._accessControl = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    WorkspacePatchRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): WorkspacePatchRequest.AsObject {
    return {
      id: this.id,
      operations: (this.operations || []).slice(),
      template: this.template ? this.template.toObject() : undefined,
      options: this.options ? this.options.toObject() : undefined,
      accessControl: this.accessControl
        ? this.accessControl.toObject()
        : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): WorkspacePatchRequest.AsProtobufJSON {
    return {
      id: this.id,
      operations: (this.operations || []).map(v => WorkspaceDataFilter[v]),
      template: this.template ? this.template.toProtobufJSON(options) : null,
      options: this.options ? this.options.toProtobufJSON(options) : null,
      accessControl: this.accessControl
        ? this.accessControl.toProtobufJSON(options)
        : null
    };
  }
}
export module WorkspacePatchRequest {
  /**
   * Standard JavaScript object representation for WorkspacePatchRequest
   */
  export interface AsObject {
    id: string;
    operations: WorkspaceDataFilter[];
    template?: haKodeproV1004.WorkspaceTemplate.AsObject;
    options?: haKodeproV1004.WorkspaceOptions.AsObject;
    accessControl?: haKodeproV1001.AccessControl.AsObject;
  }

  /**
   * Protobuf JSON representation for WorkspacePatchRequest
   */
  export interface AsProtobufJSON {
    id: string;
    operations: string[];
    template: haKodeproV1004.WorkspaceTemplate.AsProtobufJSON | null;
    options: haKodeproV1004.WorkspaceOptions.AsProtobufJSON | null;
    accessControl: haKodeproV1001.AccessControl.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for ha.kodepro.v1.Tenant
 */
export class Tenant implements GrpcMessage {
  static id = 'ha.kodepro.v1.Tenant';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new Tenant();
    Tenant.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: Tenant) {
    _instance.tenantCode = _instance.tenantCode || '';
    _instance.tenantName = _instance.tenantName || '';
    _instance.tenantStatus = _instance.tenantStatus || 0;
    _instance.callbackUrl = _instance.callbackUrl || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(_instance: Tenant, _reader: BinaryReader) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.tenantCode = _reader.readString();
          break;
        case 2:
          _instance.tenantName = _reader.readString();
          break;
        case 3:
          _instance.tenantStatus = _reader.readEnum();
          break;
        case 4:
          _instance.callbackUrl = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    Tenant.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: Tenant, _writer: BinaryWriter) {
    if (_instance.tenantCode) {
      _writer.writeString(1, _instance.tenantCode);
    }
    if (_instance.tenantName) {
      _writer.writeString(2, _instance.tenantName);
    }
    if (_instance.tenantStatus) {
      _writer.writeEnum(3, _instance.tenantStatus);
    }
    if (_instance.callbackUrl) {
      _writer.writeString(4, _instance.callbackUrl);
    }
  }

  private _tenantCode: string;
  private _tenantName: string;
  private _tenantStatus: haKodeproV1003.TenantStatus;
  private _callbackUrl: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of Tenant to deeply clone from
   */
  constructor(_value?: RecursivePartial<Tenant.AsObject>) {
    _value = _value || {};
    this.tenantCode = _value.tenantCode;
    this.tenantName = _value.tenantName;
    this.tenantStatus = _value.tenantStatus;
    this.callbackUrl = _value.callbackUrl;
    Tenant.refineValues(this);
  }
  get tenantCode(): string {
    return this._tenantCode;
  }
  set tenantCode(value: string) {
    this._tenantCode = value;
  }
  get tenantName(): string {
    return this._tenantName;
  }
  set tenantName(value: string) {
    this._tenantName = value;
  }
  get tenantStatus(): haKodeproV1003.TenantStatus {
    return this._tenantStatus;
  }
  set tenantStatus(value: haKodeproV1003.TenantStatus) {
    this._tenantStatus = value;
  }
  get callbackUrl(): string {
    return this._callbackUrl;
  }
  set callbackUrl(value: string) {
    this._callbackUrl = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    Tenant.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): Tenant.AsObject {
    return {
      tenantCode: this.tenantCode,
      tenantName: this.tenantName,
      tenantStatus: this.tenantStatus,
      callbackUrl: this.callbackUrl
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): Tenant.AsProtobufJSON {
    return {
      tenantCode: this.tenantCode,
      tenantName: this.tenantName,
      tenantStatus:
        haKodeproV1003.TenantStatus[
          this.tenantStatus === null || this.tenantStatus === undefined
            ? 0
            : this.tenantStatus
        ],
      callbackUrl: this.callbackUrl
    };
  }
}
export module Tenant {
  /**
   * Standard JavaScript object representation for Tenant
   */
  export interface AsObject {
    tenantCode: string;
    tenantName: string;
    tenantStatus: haKodeproV1003.TenantStatus;
    callbackUrl: string;
  }

  /**
   * Protobuf JSON representation for Tenant
   */
  export interface AsProtobufJSON {
    tenantCode: string;
    tenantName: string;
    tenantStatus: string;
    callbackUrl: string;
  }
}

/**
 * Message implementation for ha.kodepro.v1.CreateTenantRequest
 */
export class CreateTenantRequest implements GrpcMessage {
  static id = 'ha.kodepro.v1.CreateTenantRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CreateTenantRequest();
    CreateTenantRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CreateTenantRequest) {
    _instance.tenant = _instance.tenant || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CreateTenantRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.tenant = new Tenant();
          _reader.readMessage(
            _instance.tenant,
            Tenant.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    CreateTenantRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CreateTenantRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.tenant) {
      _writer.writeMessage(
        1,
        _instance.tenant as any,
        Tenant.serializeBinaryToWriter
      );
    }
  }

  private _tenant?: Tenant;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CreateTenantRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<CreateTenantRequest.AsObject>) {
    _value = _value || {};
    this.tenant = _value.tenant ? new Tenant(_value.tenant) : undefined;
    CreateTenantRequest.refineValues(this);
  }
  get tenant(): Tenant | undefined {
    return this._tenant;
  }
  set tenant(value: Tenant | undefined) {
    this._tenant = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CreateTenantRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CreateTenantRequest.AsObject {
    return {
      tenant: this.tenant ? this.tenant.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CreateTenantRequest.AsProtobufJSON {
    return {
      tenant: this.tenant ? this.tenant.toProtobufJSON(options) : null
    };
  }
}
export module CreateTenantRequest {
  /**
   * Standard JavaScript object representation for CreateTenantRequest
   */
  export interface AsObject {
    tenant?: Tenant.AsObject;
  }

  /**
   * Protobuf JSON representation for CreateTenantRequest
   */
  export interface AsProtobufJSON {
    tenant: Tenant.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for ha.kodepro.v1.UpdateTenantRequest
 */
export class UpdateTenantRequest implements GrpcMessage {
  static id = 'ha.kodepro.v1.UpdateTenantRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpdateTenantRequest();
    UpdateTenantRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpdateTenantRequest) {
    _instance.tenant = _instance.tenant || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UpdateTenantRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.tenant = new Tenant();
          _reader.readMessage(
            _instance.tenant,
            Tenant.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    UpdateTenantRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UpdateTenantRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.tenant) {
      _writer.writeMessage(
        1,
        _instance.tenant as any,
        Tenant.serializeBinaryToWriter
      );
    }
  }

  private _tenant?: Tenant;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpdateTenantRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<UpdateTenantRequest.AsObject>) {
    _value = _value || {};
    this.tenant = _value.tenant ? new Tenant(_value.tenant) : undefined;
    UpdateTenantRequest.refineValues(this);
  }
  get tenant(): Tenant | undefined {
    return this._tenant;
  }
  set tenant(value: Tenant | undefined) {
    this._tenant = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpdateTenantRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpdateTenantRequest.AsObject {
    return {
      tenant: this.tenant ? this.tenant.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UpdateTenantRequest.AsProtobufJSON {
    return {
      tenant: this.tenant ? this.tenant.toProtobufJSON(options) : null
    };
  }
}
export module UpdateTenantRequest {
  /**
   * Standard JavaScript object representation for UpdateTenantRequest
   */
  export interface AsObject {
    tenant?: Tenant.AsObject;
  }

  /**
   * Protobuf JSON representation for UpdateTenantRequest
   */
  export interface AsProtobufJSON {
    tenant: Tenant.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for ha.kodepro.v1.CreateUserGroupRequest
 */
export class CreateUserGroupRequest implements GrpcMessage {
  static id = 'ha.kodepro.v1.CreateUserGroupRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CreateUserGroupRequest();
    CreateUserGroupRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CreateUserGroupRequest) {
    _instance.name = _instance.name || '';
    _instance.tenantCode = _instance.tenantCode || '';
    _instance.members = _instance.members || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CreateUserGroupRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.name = _reader.readString();
          break;
        case 2:
          _instance.tenantCode = _reader.readString();
          break;
        case 3:
          const messageInitializer3 = new haKodeproV1001.UserRole();
          _reader.readMessage(
            messageInitializer3,
            haKodeproV1001.UserRole.deserializeBinaryFromReader
          );
          (_instance.members = _instance.members || []).push(
            messageInitializer3
          );
          break;
        default:
          _reader.skipField();
      }
    }

    CreateUserGroupRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CreateUserGroupRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.name) {
      _writer.writeString(1, _instance.name);
    }
    if (_instance.tenantCode) {
      _writer.writeString(2, _instance.tenantCode);
    }
    if (_instance.members && _instance.members.length) {
      _writer.writeRepeatedMessage(
        3,
        _instance.members as any,
        haKodeproV1001.UserRole.serializeBinaryToWriter
      );
    }
  }

  private _name: string;
  private _tenantCode: string;
  private _members?: haKodeproV1001.UserRole[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CreateUserGroupRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<CreateUserGroupRequest.AsObject>) {
    _value = _value || {};
    this.name = _value.name;
    this.tenantCode = _value.tenantCode;
    this.members = (_value.members || []).map(
      m => new haKodeproV1001.UserRole(m)
    );
    CreateUserGroupRequest.refineValues(this);
  }
  get name(): string {
    return this._name;
  }
  set name(value: string) {
    this._name = value;
  }
  get tenantCode(): string {
    return this._tenantCode;
  }
  set tenantCode(value: string) {
    this._tenantCode = value;
  }
  get members(): haKodeproV1001.UserRole[] | undefined {
    return this._members;
  }
  set members(value: haKodeproV1001.UserRole[] | undefined) {
    this._members = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CreateUserGroupRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CreateUserGroupRequest.AsObject {
    return {
      name: this.name,
      tenantCode: this.tenantCode,
      members: (this.members || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CreateUserGroupRequest.AsProtobufJSON {
    return {
      name: this.name,
      tenantCode: this.tenantCode,
      members: (this.members || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module CreateUserGroupRequest {
  /**
   * Standard JavaScript object representation for CreateUserGroupRequest
   */
  export interface AsObject {
    name: string;
    tenantCode: string;
    members?: haKodeproV1001.UserRole.AsObject[];
  }

  /**
   * Protobuf JSON representation for CreateUserGroupRequest
   */
  export interface AsProtobufJSON {
    name: string;
    tenantCode: string;
    members: haKodeproV1001.UserRole.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for ha.kodepro.v1.GetUserGroupRequest
 */
export class GetUserGroupRequest implements GrpcMessage {
  static id = 'ha.kodepro.v1.GetUserGroupRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetUserGroupRequest();
    GetUserGroupRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetUserGroupRequest) {
    _instance.id = _instance.id || '';
    _instance.name = _instance.name || '';
    _instance.tenantCode = _instance.tenantCode || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetUserGroupRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 2:
          _instance.name = _reader.readString();
          break;
        case 3:
          _instance.tenantCode = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GetUserGroupRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetUserGroupRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.name) {
      _writer.writeString(2, _instance.name);
    }
    if (_instance.tenantCode) {
      _writer.writeString(3, _instance.tenantCode);
    }
  }

  private _id: string;
  private _name: string;
  private _tenantCode: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetUserGroupRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetUserGroupRequest.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.name = _value.name;
    this.tenantCode = _value.tenantCode;
    GetUserGroupRequest.refineValues(this);
  }
  get id(): string {
    return this._id;
  }
  set id(value: string) {
    this._id = value;
  }
  get name(): string {
    return this._name;
  }
  set name(value: string) {
    this._name = value;
  }
  get tenantCode(): string {
    return this._tenantCode;
  }
  set tenantCode(value: string) {
    this._tenantCode = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetUserGroupRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetUserGroupRequest.AsObject {
    return {
      id: this.id,
      name: this.name,
      tenantCode: this.tenantCode
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetUserGroupRequest.AsProtobufJSON {
    return {
      id: this.id,
      name: this.name,
      tenantCode: this.tenantCode
    };
  }
}
export module GetUserGroupRequest {
  /**
   * Standard JavaScript object representation for GetUserGroupRequest
   */
  export interface AsObject {
    id: string;
    name: string;
    tenantCode: string;
  }

  /**
   * Protobuf JSON representation for GetUserGroupRequest
   */
  export interface AsProtobufJSON {
    id: string;
    name: string;
    tenantCode: string;
  }
}

/**
 * Message implementation for ha.kodepro.v1.UpdateUserGroupRequest
 */
export class UpdateUserGroupRequest implements GrpcMessage {
  static id = 'ha.kodepro.v1.UpdateUserGroupRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpdateUserGroupRequest();
    UpdateUserGroupRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpdateUserGroupRequest) {
    _instance.id = _instance.id || '';
    _instance.addMembers = _instance.addMembers || [];
    _instance.removeMembers = _instance.removeMembers || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UpdateUserGroupRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 2:
          const messageInitializer2 = new haKodeproV1001.UserRole();
          _reader.readMessage(
            messageInitializer2,
            haKodeproV1001.UserRole.deserializeBinaryFromReader
          );
          (_instance.addMembers = _instance.addMembers || []).push(
            messageInitializer2
          );
          break;
        case 3:
          const messageInitializer3 = new haKodeproV1001.UserRole();
          _reader.readMessage(
            messageInitializer3,
            haKodeproV1001.UserRole.deserializeBinaryFromReader
          );
          (_instance.removeMembers = _instance.removeMembers || []).push(
            messageInitializer3
          );
          break;
        default:
          _reader.skipField();
      }
    }

    UpdateUserGroupRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UpdateUserGroupRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.addMembers && _instance.addMembers.length) {
      _writer.writeRepeatedMessage(
        2,
        _instance.addMembers as any,
        haKodeproV1001.UserRole.serializeBinaryToWriter
      );
    }
    if (_instance.removeMembers && _instance.removeMembers.length) {
      _writer.writeRepeatedMessage(
        3,
        _instance.removeMembers as any,
        haKodeproV1001.UserRole.serializeBinaryToWriter
      );
    }
  }

  private _id: string;
  private _addMembers?: haKodeproV1001.UserRole[];
  private _removeMembers?: haKodeproV1001.UserRole[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpdateUserGroupRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<UpdateUserGroupRequest.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.addMembers = (_value.addMembers || []).map(
      m => new haKodeproV1001.UserRole(m)
    );
    this.removeMembers = (_value.removeMembers || []).map(
      m => new haKodeproV1001.UserRole(m)
    );
    UpdateUserGroupRequest.refineValues(this);
  }
  get id(): string {
    return this._id;
  }
  set id(value: string) {
    this._id = value;
  }
  get addMembers(): haKodeproV1001.UserRole[] | undefined {
    return this._addMembers;
  }
  set addMembers(value: haKodeproV1001.UserRole[] | undefined) {
    this._addMembers = value;
  }
  get removeMembers(): haKodeproV1001.UserRole[] | undefined {
    return this._removeMembers;
  }
  set removeMembers(value: haKodeproV1001.UserRole[] | undefined) {
    this._removeMembers = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpdateUserGroupRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpdateUserGroupRequest.AsObject {
    return {
      id: this.id,
      addMembers: (this.addMembers || []).map(m => m.toObject()),
      removeMembers: (this.removeMembers || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UpdateUserGroupRequest.AsProtobufJSON {
    return {
      id: this.id,
      addMembers: (this.addMembers || []).map(m => m.toProtobufJSON(options)),
      removeMembers: (this.removeMembers || []).map(m =>
        m.toProtobufJSON(options)
      )
    };
  }
}
export module UpdateUserGroupRequest {
  /**
   * Standard JavaScript object representation for UpdateUserGroupRequest
   */
  export interface AsObject {
    id: string;
    addMembers?: haKodeproV1001.UserRole.AsObject[];
    removeMembers?: haKodeproV1001.UserRole.AsObject[];
  }

  /**
   * Protobuf JSON representation for UpdateUserGroupRequest
   */
  export interface AsProtobufJSON {
    id: string;
    addMembers: haKodeproV1001.UserRole.AsProtobufJSON[] | null;
    removeMembers: haKodeproV1001.UserRole.AsProtobufJSON[] | null;
  }
}
