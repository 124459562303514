/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import { Inject, Injectable, Optional } from '@angular/core';
import {
  GrpcCallType,
  GrpcClient,
  GrpcClientFactory,
  GrpcEvent,
  GrpcMetadata
} from '@ngx-grpc/common';
import {
  GRPC_CLIENT_FACTORY,
  GrpcHandler,
  takeMessages,
  throwStatusErrors
} from '@ngx-grpc/core';
import { Observable } from 'rxjs';
import * as thisProto from './operator.pb';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import * as haKodeproV1001 from '../../../ha/kodepro/v1/common.pb';
import * as haKodeproV1002 from '../../../ha/kodepro/v1/user-mgmt.pb';
import * as haKodeproV1003 from '../../../ha/kodepro/v1/ws-template.pb';
import * as haKodeproV1004 from '../../../ha/kodepro/v1/ws-monitoring.pb';
import * as haKodeproV1005 from '../../../ha/kodepro/v1/request.pb';
import * as haKodeproV1006 from '../../../ha/kodepro/v1/response.pb';
import * as haKodeproV1007 from '../../../ha/kodepro/v1/ws-snapshot.pb';
import { GRPC_OPERATOR_CLIENT_SETTINGS } from './operator.pbconf';
/**
 * Service client implementation for ha.kodepro.v1.Operator
 */
@Injectable({ providedIn: 'any' })
export class OperatorClient {
  private client: GrpcClient<any>;

  /**
   * Raw RPC implementation for each service client method.
   * The raw methods provide more control on the incoming data and events. E.g. they can be useful to read status `OK` metadata.
   * Attention: these methods do not throw errors when non-zero status codes are received.
   */
  $raw = {
    /**
     * Unary call: /ha.kodepro.v1.Operator/GetAuthToken
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<haKodeproV1006.AuthTokenResponse>>
     */
    getAuthToken: (
      requestData: haKodeproV1005.AuthTokenRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<haKodeproV1006.AuthTokenResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/ha.kodepro.v1.Operator/GetAuthToken',
        requestData,
        requestMetadata,
        requestClass: haKodeproV1005.AuthTokenRequest,
        responseClass: haKodeproV1006.AuthTokenResponse
      });
    },
    /**
     * Unary call: /ha.kodepro.v1.Operator/ListWorkspaces
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<haKodeproV1006.WorkspaceListResponse>>
     */
    listWorkspaces: (
      requestData: haKodeproV1005.WorkspaceListRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<haKodeproV1006.WorkspaceListResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/ha.kodepro.v1.Operator/ListWorkspaces',
        requestData,
        requestMetadata,
        requestClass: haKodeproV1005.WorkspaceListRequest,
        responseClass: haKodeproV1006.WorkspaceListResponse
      });
    },
    /**
     * Unary call: /ha.kodepro.v1.Operator/GetWorkspace
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<haKodeproV1003.WorkspaceConfig>>
     */
    getWorkspace: (
      requestData: haKodeproV1005.WorkspaceRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<haKodeproV1003.WorkspaceConfig>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/ha.kodepro.v1.Operator/GetWorkspace',
        requestData,
        requestMetadata,
        requestClass: haKodeproV1005.WorkspaceRequest,
        responseClass: haKodeproV1003.WorkspaceConfig
      });
    },
    /**
     * Server streaming: /ha.kodepro.v1.Operator/CreateWorkspace
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<haKodeproV1006.WorkspaceResponse>>
     */
    createWorkspace: (
      requestData: haKodeproV1005.WorkspaceCreateRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<haKodeproV1006.WorkspaceResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.serverStream,
        client: this.client,
        path: '/ha.kodepro.v1.Operator/CreateWorkspace',
        requestData,
        requestMetadata,
        requestClass: haKodeproV1005.WorkspaceCreateRequest,
        responseClass: haKodeproV1006.WorkspaceResponse
      });
    },
    /**
     * Unary call: /ha.kodepro.v1.Operator/UpdateWorkspace
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<haKodeproV1003.WorkspaceConfig>>
     */
    updateWorkspace: (
      requestData: haKodeproV1005.WorkspaceUpdateRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<haKodeproV1003.WorkspaceConfig>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/ha.kodepro.v1.Operator/UpdateWorkspace',
        requestData,
        requestMetadata,
        requestClass: haKodeproV1005.WorkspaceUpdateRequest,
        responseClass: haKodeproV1003.WorkspaceConfig
      });
    },
    /**
     * Unary call: /ha.kodepro.v1.Operator/DeleteWorkspace
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<haKodeproV1006.WorkspaceResponse>>
     */
    deleteWorkspace: (
      requestData: haKodeproV1005.WorkspaceRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<haKodeproV1006.WorkspaceResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/ha.kodepro.v1.Operator/DeleteWorkspace',
        requestData,
        requestMetadata,
        requestClass: haKodeproV1005.WorkspaceRequest,
        responseClass: haKodeproV1006.WorkspaceResponse
      });
    },
    /**
     * Server streaming: /ha.kodepro.v1.Operator/StartWorkspace
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<haKodeproV1006.WorkspaceResponse>>
     */
    startWorkspace: (
      requestData: haKodeproV1005.WorkspaceRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<haKodeproV1006.WorkspaceResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.serverStream,
        client: this.client,
        path: '/ha.kodepro.v1.Operator/StartWorkspace',
        requestData,
        requestMetadata,
        requestClass: haKodeproV1005.WorkspaceRequest,
        responseClass: haKodeproV1006.WorkspaceResponse
      });
    },
    /**
     * Server streaming: /ha.kodepro.v1.Operator/StartTemporaryWorkspace
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<haKodeproV1006.WorkspaceResponse>>
     */
    startTemporaryWorkspace: (
      requestData: haKodeproV1005.TemporaryWorkspaceRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<haKodeproV1006.WorkspaceResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.serverStream,
        client: this.client,
        path: '/ha.kodepro.v1.Operator/StartTemporaryWorkspace',
        requestData,
        requestMetadata,
        requestClass: haKodeproV1005.TemporaryWorkspaceRequest,
        responseClass: haKodeproV1006.WorkspaceResponse
      });
    },
    /**
     * Unary call: /ha.kodepro.v1.Operator/StopWorkspace
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<haKodeproV1006.WorkspaceResponse>>
     */
    stopWorkspace: (
      requestData: haKodeproV1005.StopWorkspaceRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<haKodeproV1006.WorkspaceResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/ha.kodepro.v1.Operator/StopWorkspace',
        requestData,
        requestMetadata,
        requestClass: haKodeproV1005.StopWorkspaceRequest,
        responseClass: haKodeproV1006.WorkspaceResponse
      });
    },
    /**
     * Unary call: /ha.kodepro.v1.Operator/ExecuteCommand
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<haKodeproV1006.CommandResponse>>
     */
    executeCommand: (
      requestData: haKodeproV1005.CommandRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<haKodeproV1006.CommandResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/ha.kodepro.v1.Operator/ExecuteCommand',
        requestData,
        requestMetadata,
        requestClass: haKodeproV1005.CommandRequest,
        responseClass: haKodeproV1006.CommandResponse
      });
    },
    /**
     * Unary call: /ha.kodepro.v1.Operator/RegisterWorkspaceActivity
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<haKodeproV1004.WorkspaceActivityResponse>>
     */
    registerWorkspaceActivity: (
      requestData: haKodeproV1004.WorkspaceActivityRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<haKodeproV1004.WorkspaceActivityResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/ha.kodepro.v1.Operator/RegisterWorkspaceActivity',
        requestData,
        requestMetadata,
        requestClass: haKodeproV1004.WorkspaceActivityRequest,
        responseClass: haKodeproV1004.WorkspaceActivityResponse
      });
    },
    /**
     * Unary call: /ha.kodepro.v1.Operator/TriggerJob
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<haKodeproV1006.TriggerJobResponse>>
     */
    triggerJob: (
      requestData: haKodeproV1005.TriggerJobRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<haKodeproV1006.TriggerJobResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/ha.kodepro.v1.Operator/TriggerJob',
        requestData,
        requestMetadata,
        requestClass: haKodeproV1005.TriggerJobRequest,
        responseClass: haKodeproV1006.TriggerJobResponse
      });
    },
    /**
     * Unary call: /ha.kodepro.v1.Operator/WorkspaceSnapshot
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<haKodeproV1007.WorkspaceSnapshotResponse>>
     */
    workspaceSnapshot: (
      requestData: haKodeproV1007.WorkspaceSnapshotRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<haKodeproV1007.WorkspaceSnapshotResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/ha.kodepro.v1.Operator/WorkspaceSnapshot',
        requestData,
        requestMetadata,
        requestClass: haKodeproV1007.WorkspaceSnapshotRequest,
        responseClass: haKodeproV1007.WorkspaceSnapshotResponse
      });
    },
    /**
     * Unary call: /ha.kodepro.v1.Operator/WorkspaceGitDifferences
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<haKodeproV1007.WorkspaceGitDifferencesResponse>>
     */
    workspaceGitDifferences: (
      requestData: haKodeproV1007.WorkspaceGitDifferencesRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<
      GrpcEvent<haKodeproV1007.WorkspaceGitDifferencesResponse>
    > => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/ha.kodepro.v1.Operator/WorkspaceGitDifferences',
        requestData,
        requestMetadata,
        requestClass: haKodeproV1007.WorkspaceGitDifferencesRequest,
        responseClass: haKodeproV1007.WorkspaceGitDifferencesResponse
      });
    },
    /**
     * Unary call: /ha.kodepro.v1.Operator/CreateTenant
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<haKodeproV1006.CreateTenantResponse>>
     */
    createTenant: (
      requestData: haKodeproV1005.CreateTenantRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<haKodeproV1006.CreateTenantResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/ha.kodepro.v1.Operator/CreateTenant',
        requestData,
        requestMetadata,
        requestClass: haKodeproV1005.CreateTenantRequest,
        responseClass: haKodeproV1006.CreateTenantResponse
      });
    },
    /**
     * Unary call: /ha.kodepro.v1.Operator/UpdateTenant
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<haKodeproV1006.UpdateTenantResponse>>
     */
    updateTenant: (
      requestData: haKodeproV1005.UpdateTenantRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<haKodeproV1006.UpdateTenantResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/ha.kodepro.v1.Operator/UpdateTenant',
        requestData,
        requestMetadata,
        requestClass: haKodeproV1005.UpdateTenantRequest,
        responseClass: haKodeproV1006.UpdateTenantResponse
      });
    },
    /**
     * Unary call: /ha.kodepro.v1.Operator/CreateUserGroup
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<haKodeproV1006.CreateUserGroupResponse>>
     */
    createUserGroup: (
      requestData: haKodeproV1005.CreateUserGroupRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<haKodeproV1006.CreateUserGroupResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/ha.kodepro.v1.Operator/CreateUserGroup',
        requestData,
        requestMetadata,
        requestClass: haKodeproV1005.CreateUserGroupRequest,
        responseClass: haKodeproV1006.CreateUserGroupResponse
      });
    },
    /**
     * Unary call: /ha.kodepro.v1.Operator/UpdateUserGroup
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<haKodeproV1006.UpdateUserGroupResponse>>
     */
    updateUserGroup: (
      requestData: haKodeproV1005.UpdateUserGroupRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<haKodeproV1006.UpdateUserGroupResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/ha.kodepro.v1.Operator/UpdateUserGroup',
        requestData,
        requestMetadata,
        requestClass: haKodeproV1005.UpdateUserGroupRequest,
        responseClass: haKodeproV1006.UpdateUserGroupResponse
      });
    },
    /**
     * Unary call: /ha.kodepro.v1.Operator/GetUserGroup
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<haKodeproV1006.GetUserGroupResponse>>
     */
    getUserGroup: (
      requestData: haKodeproV1005.GetUserGroupRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<haKodeproV1006.GetUserGroupResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/ha.kodepro.v1.Operator/GetUserGroup',
        requestData,
        requestMetadata,
        requestClass: haKodeproV1005.GetUserGroupRequest,
        responseClass: haKodeproV1006.GetUserGroupResponse
      });
    }
  };

  constructor(
    @Optional() @Inject(GRPC_OPERATOR_CLIENT_SETTINGS) settings: any,
    @Inject(GRPC_CLIENT_FACTORY) clientFactory: GrpcClientFactory<any>,
    private handler: GrpcHandler
  ) {
    this.client = clientFactory.createClient(
      'ha.kodepro.v1.Operator',
      settings
    );
  }

  /**
   * Unary call @/ha.kodepro.v1.Operator/GetAuthToken
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<haKodeproV1006.AuthTokenResponse>
   */
  getAuthToken(
    requestData: haKodeproV1005.AuthTokenRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<haKodeproV1006.AuthTokenResponse> {
    return this.$raw
      .getAuthToken(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/ha.kodepro.v1.Operator/ListWorkspaces
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<haKodeproV1006.WorkspaceListResponse>
   */
  listWorkspaces(
    requestData: haKodeproV1005.WorkspaceListRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<haKodeproV1006.WorkspaceListResponse> {
    return this.$raw
      .listWorkspaces(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/ha.kodepro.v1.Operator/GetWorkspace
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<haKodeproV1003.WorkspaceConfig>
   */
  getWorkspace(
    requestData: haKodeproV1005.WorkspaceRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<haKodeproV1003.WorkspaceConfig> {
    return this.$raw
      .getWorkspace(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Server streaming @/ha.kodepro.v1.Operator/CreateWorkspace
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<haKodeproV1006.WorkspaceResponse>
   */
  createWorkspace(
    requestData: haKodeproV1005.WorkspaceCreateRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<haKodeproV1006.WorkspaceResponse> {
    return this.$raw
      .createWorkspace(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/ha.kodepro.v1.Operator/UpdateWorkspace
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<haKodeproV1003.WorkspaceConfig>
   */
  updateWorkspace(
    requestData: haKodeproV1005.WorkspaceUpdateRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<haKodeproV1003.WorkspaceConfig> {
    return this.$raw
      .updateWorkspace(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/ha.kodepro.v1.Operator/DeleteWorkspace
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<haKodeproV1006.WorkspaceResponse>
   */
  deleteWorkspace(
    requestData: haKodeproV1005.WorkspaceRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<haKodeproV1006.WorkspaceResponse> {
    return this.$raw
      .deleteWorkspace(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Server streaming @/ha.kodepro.v1.Operator/StartWorkspace
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<haKodeproV1006.WorkspaceResponse>
   */
  startWorkspace(
    requestData: haKodeproV1005.WorkspaceRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<haKodeproV1006.WorkspaceResponse> {
    return this.$raw
      .startWorkspace(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Server streaming @/ha.kodepro.v1.Operator/StartTemporaryWorkspace
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<haKodeproV1006.WorkspaceResponse>
   */
  startTemporaryWorkspace(
    requestData: haKodeproV1005.TemporaryWorkspaceRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<haKodeproV1006.WorkspaceResponse> {
    return this.$raw
      .startTemporaryWorkspace(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/ha.kodepro.v1.Operator/StopWorkspace
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<haKodeproV1006.WorkspaceResponse>
   */
  stopWorkspace(
    requestData: haKodeproV1005.StopWorkspaceRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<haKodeproV1006.WorkspaceResponse> {
    return this.$raw
      .stopWorkspace(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/ha.kodepro.v1.Operator/ExecuteCommand
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<haKodeproV1006.CommandResponse>
   */
  executeCommand(
    requestData: haKodeproV1005.CommandRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<haKodeproV1006.CommandResponse> {
    return this.$raw
      .executeCommand(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/ha.kodepro.v1.Operator/RegisterWorkspaceActivity
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<haKodeproV1004.WorkspaceActivityResponse>
   */
  registerWorkspaceActivity(
    requestData: haKodeproV1004.WorkspaceActivityRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<haKodeproV1004.WorkspaceActivityResponse> {
    return this.$raw
      .registerWorkspaceActivity(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/ha.kodepro.v1.Operator/TriggerJob
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<haKodeproV1006.TriggerJobResponse>
   */
  triggerJob(
    requestData: haKodeproV1005.TriggerJobRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<haKodeproV1006.TriggerJobResponse> {
    return this.$raw
      .triggerJob(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/ha.kodepro.v1.Operator/WorkspaceSnapshot
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<haKodeproV1007.WorkspaceSnapshotResponse>
   */
  workspaceSnapshot(
    requestData: haKodeproV1007.WorkspaceSnapshotRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<haKodeproV1007.WorkspaceSnapshotResponse> {
    return this.$raw
      .workspaceSnapshot(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/ha.kodepro.v1.Operator/WorkspaceGitDifferences
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<haKodeproV1007.WorkspaceGitDifferencesResponse>
   */
  workspaceGitDifferences(
    requestData: haKodeproV1007.WorkspaceGitDifferencesRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<haKodeproV1007.WorkspaceGitDifferencesResponse> {
    return this.$raw
      .workspaceGitDifferences(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/ha.kodepro.v1.Operator/CreateTenant
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<haKodeproV1006.CreateTenantResponse>
   */
  createTenant(
    requestData: haKodeproV1005.CreateTenantRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<haKodeproV1006.CreateTenantResponse> {
    return this.$raw
      .createTenant(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/ha.kodepro.v1.Operator/UpdateTenant
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<haKodeproV1006.UpdateTenantResponse>
   */
  updateTenant(
    requestData: haKodeproV1005.UpdateTenantRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<haKodeproV1006.UpdateTenantResponse> {
    return this.$raw
      .updateTenant(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/ha.kodepro.v1.Operator/CreateUserGroup
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<haKodeproV1006.CreateUserGroupResponse>
   */
  createUserGroup(
    requestData: haKodeproV1005.CreateUserGroupRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<haKodeproV1006.CreateUserGroupResponse> {
    return this.$raw
      .createUserGroup(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/ha.kodepro.v1.Operator/UpdateUserGroup
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<haKodeproV1006.UpdateUserGroupResponse>
   */
  updateUserGroup(
    requestData: haKodeproV1005.UpdateUserGroupRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<haKodeproV1006.UpdateUserGroupResponse> {
    return this.$raw
      .updateUserGroup(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/ha.kodepro.v1.Operator/GetUserGroup
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<haKodeproV1006.GetUserGroupResponse>
   */
  getUserGroup(
    requestData: haKodeproV1005.GetUserGroupRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<haKodeproV1006.GetUserGroupResponse> {
    return this.$raw
      .getUserGroup(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }
}
