/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
/**
 * Message implementation for ha.kodepro.v1.WorkspaceActivityRequest
 */
export class WorkspaceActivityRequest implements GrpcMessage {
  static id = 'ha.kodepro.v1.WorkspaceActivityRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new WorkspaceActivityRequest();
    WorkspaceActivityRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: WorkspaceActivityRequest) {
    _instance.workspaceId = _instance.workspaceId || '';
    _instance.activity = _instance.activity || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: WorkspaceActivityRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.workspaceId = _reader.readString();
          break;
        case 2:
          const messageInitializer2 = new WorkspaceActivity();
          _reader.readMessage(
            messageInitializer2,
            WorkspaceActivity.deserializeBinaryFromReader
          );
          (_instance.activity = _instance.activity || []).push(
            messageInitializer2
          );
          break;
        default:
          _reader.skipField();
      }
    }

    WorkspaceActivityRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: WorkspaceActivityRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.workspaceId) {
      _writer.writeString(1, _instance.workspaceId);
    }
    if (_instance.activity && _instance.activity.length) {
      _writer.writeRepeatedMessage(
        2,
        _instance.activity as any,
        WorkspaceActivity.serializeBinaryToWriter
      );
    }
  }

  private _workspaceId: string;
  private _activity?: WorkspaceActivity[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of WorkspaceActivityRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<WorkspaceActivityRequest.AsObject>) {
    _value = _value || {};
    this.workspaceId = _value.workspaceId;
    this.activity = (_value.activity || []).map(m => new WorkspaceActivity(m));
    WorkspaceActivityRequest.refineValues(this);
  }
  get workspaceId(): string {
    return this._workspaceId;
  }
  set workspaceId(value: string) {
    this._workspaceId = value;
  }
  get activity(): WorkspaceActivity[] | undefined {
    return this._activity;
  }
  set activity(value: WorkspaceActivity[] | undefined) {
    this._activity = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    WorkspaceActivityRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): WorkspaceActivityRequest.AsObject {
    return {
      workspaceId: this.workspaceId,
      activity: (this.activity || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): WorkspaceActivityRequest.AsProtobufJSON {
    return {
      workspaceId: this.workspaceId,
      activity: (this.activity || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module WorkspaceActivityRequest {
  /**
   * Standard JavaScript object representation for WorkspaceActivityRequest
   */
  export interface AsObject {
    workspaceId: string;
    activity?: WorkspaceActivity.AsObject[];
  }

  /**
   * Protobuf JSON representation for WorkspaceActivityRequest
   */
  export interface AsProtobufJSON {
    workspaceId: string;
    activity: WorkspaceActivity.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for ha.kodepro.v1.WorkspaceActivityResponse
 */
export class WorkspaceActivityResponse implements GrpcMessage {
  static id = 'ha.kodepro.v1.WorkspaceActivityResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new WorkspaceActivityResponse();
    WorkspaceActivityResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: WorkspaceActivityResponse) {
    _instance.status = _instance.status || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: WorkspaceActivityResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.status = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    WorkspaceActivityResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: WorkspaceActivityResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.status) {
      _writer.writeString(1, _instance.status);
    }
  }

  private _status: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of WorkspaceActivityResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<WorkspaceActivityResponse.AsObject>) {
    _value = _value || {};
    this.status = _value.status;
    WorkspaceActivityResponse.refineValues(this);
  }
  get status(): string {
    return this._status;
  }
  set status(value: string) {
    this._status = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    WorkspaceActivityResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): WorkspaceActivityResponse.AsObject {
    return {
      status: this.status
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): WorkspaceActivityResponse.AsProtobufJSON {
    return {
      status: this.status
    };
  }
}
export module WorkspaceActivityResponse {
  /**
   * Standard JavaScript object representation for WorkspaceActivityResponse
   */
  export interface AsObject {
    status: string;
  }

  /**
   * Protobuf JSON representation for WorkspaceActivityResponse
   */
  export interface AsProtobufJSON {
    status: string;
  }
}

/**
 * Message implementation for ha.kodepro.v1.WorkspaceActivity
 */
export class WorkspaceActivity implements GrpcMessage {
  static id = 'ha.kodepro.v1.WorkspaceActivity';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new WorkspaceActivity();
    WorkspaceActivity.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: WorkspaceActivity) {
    _instance.type = _instance.type || '';
    _instance.event = _instance.event || '';
    _instance.timestamp = _instance.timestamp || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: WorkspaceActivity,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.type = _reader.readString();
          break;
        case 2:
          _instance.event = _reader.readString();
          break;
        case 3:
          _instance.timestamp = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.timestamp,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    WorkspaceActivity.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: WorkspaceActivity,
    _writer: BinaryWriter
  ) {
    if (_instance.type) {
      _writer.writeString(1, _instance.type);
    }
    if (_instance.event) {
      _writer.writeString(2, _instance.event);
    }
    if (_instance.timestamp) {
      _writer.writeMessage(
        3,
        _instance.timestamp as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
  }

  private _type: string;
  private _event: string;
  private _timestamp?: googleProtobuf000.Timestamp;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of WorkspaceActivity to deeply clone from
   */
  constructor(_value?: RecursivePartial<WorkspaceActivity.AsObject>) {
    _value = _value || {};
    this.type = _value.type;
    this.event = _value.event;
    this.timestamp = _value.timestamp
      ? new googleProtobuf000.Timestamp(_value.timestamp)
      : undefined;
    WorkspaceActivity.refineValues(this);
  }
  get type(): string {
    return this._type;
  }
  set type(value: string) {
    this._type = value;
  }
  get event(): string {
    return this._event;
  }
  set event(value: string) {
    this._event = value;
  }
  get timestamp(): googleProtobuf000.Timestamp | undefined {
    return this._timestamp;
  }
  set timestamp(value: googleProtobuf000.Timestamp | undefined) {
    this._timestamp = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    WorkspaceActivity.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): WorkspaceActivity.AsObject {
    return {
      type: this.type,
      event: this.event,
      timestamp: this.timestamp ? this.timestamp.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): WorkspaceActivity.AsProtobufJSON {
    return {
      type: this.type,
      event: this.event,
      timestamp: this.timestamp ? this.timestamp.toProtobufJSON(options) : null
    };
  }
}
export module WorkspaceActivity {
  /**
   * Standard JavaScript object representation for WorkspaceActivity
   */
  export interface AsObject {
    type: string;
    event: string;
    timestamp?: googleProtobuf000.Timestamp.AsObject;
  }

  /**
   * Protobuf JSON representation for WorkspaceActivity
   */
  export interface AsProtobufJSON {
    type: string;
    event: string;
    timestamp: googleProtobuf000.Timestamp.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for ha.kodepro.v1.WorkspaceMonitoring
 */
export class WorkspaceMonitoring implements GrpcMessage {
  static id = 'ha.kodepro.v1.WorkspaceMonitoring';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new WorkspaceMonitoring();
    WorkspaceMonitoring.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: WorkspaceMonitoring) {
    _instance.lastActivityTimestamp =
      _instance.lastActivityTimestamp || undefined;
    _instance.activityList = _instance.activityList || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: WorkspaceMonitoring,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.lastActivityTimestamp = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.lastActivityTimestamp,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 2:
          const messageInitializer2 = new WorkspaceActivity();
          _reader.readMessage(
            messageInitializer2,
            WorkspaceActivity.deserializeBinaryFromReader
          );
          (_instance.activityList = _instance.activityList || []).push(
            messageInitializer2
          );
          break;
        default:
          _reader.skipField();
      }
    }

    WorkspaceMonitoring.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: WorkspaceMonitoring,
    _writer: BinaryWriter
  ) {
    if (_instance.lastActivityTimestamp) {
      _writer.writeMessage(
        1,
        _instance.lastActivityTimestamp as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.activityList && _instance.activityList.length) {
      _writer.writeRepeatedMessage(
        2,
        _instance.activityList as any,
        WorkspaceActivity.serializeBinaryToWriter
      );
    }
  }

  private _lastActivityTimestamp?: googleProtobuf000.Timestamp;
  private _activityList?: WorkspaceActivity[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of WorkspaceMonitoring to deeply clone from
   */
  constructor(_value?: RecursivePartial<WorkspaceMonitoring.AsObject>) {
    _value = _value || {};
    this.lastActivityTimestamp = _value.lastActivityTimestamp
      ? new googleProtobuf000.Timestamp(_value.lastActivityTimestamp)
      : undefined;
    this.activityList = (_value.activityList || []).map(
      m => new WorkspaceActivity(m)
    );
    WorkspaceMonitoring.refineValues(this);
  }
  get lastActivityTimestamp(): googleProtobuf000.Timestamp | undefined {
    return this._lastActivityTimestamp;
  }
  set lastActivityTimestamp(value: googleProtobuf000.Timestamp | undefined) {
    this._lastActivityTimestamp = value;
  }
  get activityList(): WorkspaceActivity[] | undefined {
    return this._activityList;
  }
  set activityList(value: WorkspaceActivity[] | undefined) {
    this._activityList = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    WorkspaceMonitoring.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): WorkspaceMonitoring.AsObject {
    return {
      lastActivityTimestamp: this.lastActivityTimestamp
        ? this.lastActivityTimestamp.toObject()
        : undefined,
      activityList: (this.activityList || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): WorkspaceMonitoring.AsProtobufJSON {
    return {
      lastActivityTimestamp: this.lastActivityTimestamp
        ? this.lastActivityTimestamp.toProtobufJSON(options)
        : null,
      activityList: (this.activityList || []).map(m =>
        m.toProtobufJSON(options)
      )
    };
  }
}
export module WorkspaceMonitoring {
  /**
   * Standard JavaScript object representation for WorkspaceMonitoring
   */
  export interface AsObject {
    lastActivityTimestamp?: googleProtobuf000.Timestamp.AsObject;
    activityList?: WorkspaceActivity.AsObject[];
  }

  /**
   * Protobuf JSON representation for WorkspaceMonitoring
   */
  export interface AsProtobufJSON {
    lastActivityTimestamp: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    activityList: WorkspaceActivity.AsProtobufJSON[] | null;
  }
}
