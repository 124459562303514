import { Injectable } from "@angular/core";
import { GrpcEvent, GrpcMetadata } from "@ngx-grpc/common";
import { OperatorClient } from "proto/ha/kodepro/v1/operator.pbsc";
import { TemporaryWorkspaceRequest, WorkspaceRequest } from "proto/ha/kodepro/v1/request.pb";
import { WorkspaceResponse } from "proto/ha/kodepro/v1/response.pb";
import { WorkspaceConfig } from "proto/ha/kodepro/v1/ws-template.pb";
import { Observable } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { KeycloakApiService } from "../keycloak/keycloak-api.service";

@Injectable()
export class KodeproGrpcService{
    constructor(
        private kpOperatorClient:OperatorClient,
        private keycloakAPi: KeycloakApiService){

    }
    private createMetaData(token: string): GrpcMetadata {
        const metaData = new GrpcMetadata();
        metaData.set('authorization',`Bearer ${token}`);
        return metaData;
    }

    public startWorkspace(request: WorkspaceRequest): Observable<GrpcEvent<WorkspaceResponse>> {
        return this.keycloakAPi.getBearerToken().pipe(
            map(token => this.createMetaData(token)),
            mergeMap(metaData => this.kpOperatorClient.$raw.startWorkspace(request,metaData)
            )
        );
    }

    public getWorkspace(request: WorkspaceRequest) : Observable<WorkspaceConfig>{
        return this.keycloakAPi.getBearerToken().pipe(
            map(token => this.createMetaData(token)),
            mergeMap(metaData => this.kpOperatorClient.getWorkspace(request,metaData))
        );
    }
    public startTempWorkspace(request: TemporaryWorkspaceRequest) :Observable<GrpcEvent<WorkspaceResponse>> {
        return this.keycloakAPi.getBearerToken().pipe(
            map(token => this.createMetaData(token)),
            mergeMap(metaData => this.kpOperatorClient.$raw.startTemporaryWorkspace(request,metaData))
        );
    }
    
}