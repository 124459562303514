/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import * as haKodeproV1001 from '../../../ha/kodepro/v1/user-mgmt.pb';
import * as haKodeproV1002 from '../../../ha/kodepro/v1/ws-monitoring.pb';
/**
 * Message implementation for ha.kodepro.v1.WorkspaceConfig
 */
export class WorkspaceConfig implements GrpcMessage {
  static id = 'ha.kodepro.v1.WorkspaceConfig';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new WorkspaceConfig();
    WorkspaceConfig.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: WorkspaceConfig) {
    _instance.id = _instance.id || '';
    _instance.template = _instance.template || undefined;
    _instance.status = _instance.status || '';
    _instance.url = _instance.url || '';
    _instance.namespace = _instance.namespace || '';
    _instance.createdTimestamp = _instance.createdTimestamp || undefined;
    _instance.accessControl = _instance.accessControl || undefined;
    _instance.options = _instance.options || undefined;
    _instance.monitoring = _instance.monitoring || undefined;
    _instance.temporaryWorkspaceFor = _instance.temporaryWorkspaceFor || '';
    _instance.lastStartedTimestamp =
      _instance.lastStartedTimestamp || undefined;
    _instance.lastStoppedTimestamp =
      _instance.lastStoppedTimestamp || undefined;
    _instance.timeSpentInSec = _instance.timeSpentInSec || 0;
    _instance.tenantCode = _instance.tenantCode || '';
    _instance.startedBy = _instance.startedBy || '';
    _instance.startDate = _instance.startDate || undefined;
    _instance.endDate = _instance.endDate || undefined;
    _instance.userGroupId = _instance.userGroupId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: WorkspaceConfig,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 2:
          _instance.template = new WorkspaceTemplate();
          _reader.readMessage(
            _instance.template,
            WorkspaceTemplate.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.status = _reader.readString();
          break;
        case 4:
          _instance.url = _reader.readString();
          break;
        case 5:
          _instance.namespace = _reader.readString();
          break;
        case 6:
          _instance.createdTimestamp = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.createdTimestamp,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 7:
          _instance.accessControl = new haKodeproV1001.AccessControl();
          _reader.readMessage(
            _instance.accessControl,
            haKodeproV1001.AccessControl.deserializeBinaryFromReader
          );
          break;
        case 8:
          _instance.options = new WorkspaceOptions();
          _reader.readMessage(
            _instance.options,
            WorkspaceOptions.deserializeBinaryFromReader
          );
          break;
        case 9:
          _instance.monitoring = new haKodeproV1002.WorkspaceMonitoring();
          _reader.readMessage(
            _instance.monitoring,
            haKodeproV1002.WorkspaceMonitoring.deserializeBinaryFromReader
          );
          break;
        case 10:
          _instance.temporaryWorkspaceFor = _reader.readString();
          break;
        case 11:
          _instance.lastStartedTimestamp = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.lastStartedTimestamp,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 12:
          _instance.lastStoppedTimestamp = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.lastStoppedTimestamp,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 13:
          _instance.timeSpentInSec = _reader.readInt32();
          break;
        case 14:
          _instance.tenantCode = _reader.readString();
          break;
        case 15:
          _instance.startedBy = _reader.readString();
          break;
        case 16:
          _instance.startDate = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.startDate,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 17:
          _instance.endDate = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.endDate,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 18:
          _instance.userGroupId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    WorkspaceConfig.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: WorkspaceConfig,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.template) {
      _writer.writeMessage(
        2,
        _instance.template as any,
        WorkspaceTemplate.serializeBinaryToWriter
      );
    }
    if (_instance.status) {
      _writer.writeString(3, _instance.status);
    }
    if (_instance.url) {
      _writer.writeString(4, _instance.url);
    }
    if (_instance.namespace) {
      _writer.writeString(5, _instance.namespace);
    }
    if (_instance.createdTimestamp) {
      _writer.writeMessage(
        6,
        _instance.createdTimestamp as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.accessControl) {
      _writer.writeMessage(
        7,
        _instance.accessControl as any,
        haKodeproV1001.AccessControl.serializeBinaryToWriter
      );
    }
    if (_instance.options) {
      _writer.writeMessage(
        8,
        _instance.options as any,
        WorkspaceOptions.serializeBinaryToWriter
      );
    }
    if (_instance.monitoring) {
      _writer.writeMessage(
        9,
        _instance.monitoring as any,
        haKodeproV1002.WorkspaceMonitoring.serializeBinaryToWriter
      );
    }
    if (_instance.temporaryWorkspaceFor) {
      _writer.writeString(10, _instance.temporaryWorkspaceFor);
    }
    if (_instance.lastStartedTimestamp) {
      _writer.writeMessage(
        11,
        _instance.lastStartedTimestamp as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.lastStoppedTimestamp) {
      _writer.writeMessage(
        12,
        _instance.lastStoppedTimestamp as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.timeSpentInSec) {
      _writer.writeInt32(13, _instance.timeSpentInSec);
    }
    if (_instance.tenantCode) {
      _writer.writeString(14, _instance.tenantCode);
    }
    if (_instance.startedBy) {
      _writer.writeString(15, _instance.startedBy);
    }
    if (_instance.startDate) {
      _writer.writeMessage(
        16,
        _instance.startDate as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.endDate) {
      _writer.writeMessage(
        17,
        _instance.endDate as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.userGroupId) {
      _writer.writeString(18, _instance.userGroupId);
    }
  }

  private _id: string;
  private _template?: WorkspaceTemplate;
  private _status: string;
  private _url: string;
  private _namespace: string;
  private _createdTimestamp?: googleProtobuf000.Timestamp;
  private _accessControl?: haKodeproV1001.AccessControl;
  private _options?: WorkspaceOptions;
  private _monitoring?: haKodeproV1002.WorkspaceMonitoring;
  private _temporaryWorkspaceFor: string;
  private _lastStartedTimestamp?: googleProtobuf000.Timestamp;
  private _lastStoppedTimestamp?: googleProtobuf000.Timestamp;
  private _timeSpentInSec: number;
  private _tenantCode: string;
  private _startedBy: string;
  private _startDate?: googleProtobuf000.Timestamp;
  private _endDate?: googleProtobuf000.Timestamp;
  private _userGroupId: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of WorkspaceConfig to deeply clone from
   */
  constructor(_value?: RecursivePartial<WorkspaceConfig.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.template = _value.template
      ? new WorkspaceTemplate(_value.template)
      : undefined;
    this.status = _value.status;
    this.url = _value.url;
    this.namespace = _value.namespace;
    this.createdTimestamp = _value.createdTimestamp
      ? new googleProtobuf000.Timestamp(_value.createdTimestamp)
      : undefined;
    this.accessControl = _value.accessControl
      ? new haKodeproV1001.AccessControl(_value.accessControl)
      : undefined;
    this.options = _value.options
      ? new WorkspaceOptions(_value.options)
      : undefined;
    this.monitoring = _value.monitoring
      ? new haKodeproV1002.WorkspaceMonitoring(_value.monitoring)
      : undefined;
    this.temporaryWorkspaceFor = _value.temporaryWorkspaceFor;
    this.lastStartedTimestamp = _value.lastStartedTimestamp
      ? new googleProtobuf000.Timestamp(_value.lastStartedTimestamp)
      : undefined;
    this.lastStoppedTimestamp = _value.lastStoppedTimestamp
      ? new googleProtobuf000.Timestamp(_value.lastStoppedTimestamp)
      : undefined;
    this.timeSpentInSec = _value.timeSpentInSec;
    this.tenantCode = _value.tenantCode;
    this.startedBy = _value.startedBy;
    this.startDate = _value.startDate
      ? new googleProtobuf000.Timestamp(_value.startDate)
      : undefined;
    this.endDate = _value.endDate
      ? new googleProtobuf000.Timestamp(_value.endDate)
      : undefined;
    this.userGroupId = _value.userGroupId;
    WorkspaceConfig.refineValues(this);
  }
  get id(): string {
    return this._id;
  }
  set id(value: string) {
    this._id = value;
  }
  get template(): WorkspaceTemplate | undefined {
    return this._template;
  }
  set template(value: WorkspaceTemplate | undefined) {
    this._template = value;
  }
  get status(): string {
    return this._status;
  }
  set status(value: string) {
    this._status = value;
  }
  get url(): string {
    return this._url;
  }
  set url(value: string) {
    this._url = value;
  }
  get namespace(): string {
    return this._namespace;
  }
  set namespace(value: string) {
    this._namespace = value;
  }
  get createdTimestamp(): googleProtobuf000.Timestamp | undefined {
    return this._createdTimestamp;
  }
  set createdTimestamp(value: googleProtobuf000.Timestamp | undefined) {
    this._createdTimestamp = value;
  }
  get accessControl(): haKodeproV1001.AccessControl | undefined {
    return this._accessControl;
  }
  set accessControl(value: haKodeproV1001.AccessControl | undefined) {
    this._accessControl = value;
  }
  get options(): WorkspaceOptions | undefined {
    return this._options;
  }
  set options(value: WorkspaceOptions | undefined) {
    this._options = value;
  }
  get monitoring(): haKodeproV1002.WorkspaceMonitoring | undefined {
    return this._monitoring;
  }
  set monitoring(value: haKodeproV1002.WorkspaceMonitoring | undefined) {
    this._monitoring = value;
  }
  get temporaryWorkspaceFor(): string {
    return this._temporaryWorkspaceFor;
  }
  set temporaryWorkspaceFor(value: string) {
    this._temporaryWorkspaceFor = value;
  }
  get lastStartedTimestamp(): googleProtobuf000.Timestamp | undefined {
    return this._lastStartedTimestamp;
  }
  set lastStartedTimestamp(value: googleProtobuf000.Timestamp | undefined) {
    this._lastStartedTimestamp = value;
  }
  get lastStoppedTimestamp(): googleProtobuf000.Timestamp | undefined {
    return this._lastStoppedTimestamp;
  }
  set lastStoppedTimestamp(value: googleProtobuf000.Timestamp | undefined) {
    this._lastStoppedTimestamp = value;
  }
  get timeSpentInSec(): number {
    return this._timeSpentInSec;
  }
  set timeSpentInSec(value: number) {
    this._timeSpentInSec = value;
  }
  get tenantCode(): string {
    return this._tenantCode;
  }
  set tenantCode(value: string) {
    this._tenantCode = value;
  }
  get startedBy(): string {
    return this._startedBy;
  }
  set startedBy(value: string) {
    this._startedBy = value;
  }
  get startDate(): googleProtobuf000.Timestamp | undefined {
    return this._startDate;
  }
  set startDate(value: googleProtobuf000.Timestamp | undefined) {
    this._startDate = value;
  }
  get endDate(): googleProtobuf000.Timestamp | undefined {
    return this._endDate;
  }
  set endDate(value: googleProtobuf000.Timestamp | undefined) {
    this._endDate = value;
  }
  get userGroupId(): string {
    return this._userGroupId;
  }
  set userGroupId(value: string) {
    this._userGroupId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    WorkspaceConfig.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): WorkspaceConfig.AsObject {
    return {
      id: this.id,
      template: this.template ? this.template.toObject() : undefined,
      status: this.status,
      url: this.url,
      namespace: this.namespace,
      createdTimestamp: this.createdTimestamp
        ? this.createdTimestamp.toObject()
        : undefined,
      accessControl: this.accessControl
        ? this.accessControl.toObject()
        : undefined,
      options: this.options ? this.options.toObject() : undefined,
      monitoring: this.monitoring ? this.monitoring.toObject() : undefined,
      temporaryWorkspaceFor: this.temporaryWorkspaceFor,
      lastStartedTimestamp: this.lastStartedTimestamp
        ? this.lastStartedTimestamp.toObject()
        : undefined,
      lastStoppedTimestamp: this.lastStoppedTimestamp
        ? this.lastStoppedTimestamp.toObject()
        : undefined,
      timeSpentInSec: this.timeSpentInSec,
      tenantCode: this.tenantCode,
      startedBy: this.startedBy,
      startDate: this.startDate ? this.startDate.toObject() : undefined,
      endDate: this.endDate ? this.endDate.toObject() : undefined,
      userGroupId: this.userGroupId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): WorkspaceConfig.AsProtobufJSON {
    return {
      id: this.id,
      template: this.template ? this.template.toProtobufJSON(options) : null,
      status: this.status,
      url: this.url,
      namespace: this.namespace,
      createdTimestamp: this.createdTimestamp
        ? this.createdTimestamp.toProtobufJSON(options)
        : null,
      accessControl: this.accessControl
        ? this.accessControl.toProtobufJSON(options)
        : null,
      options: this.options ? this.options.toProtobufJSON(options) : null,
      monitoring: this.monitoring
        ? this.monitoring.toProtobufJSON(options)
        : null,
      temporaryWorkspaceFor: this.temporaryWorkspaceFor,
      lastStartedTimestamp: this.lastStartedTimestamp
        ? this.lastStartedTimestamp.toProtobufJSON(options)
        : null,
      lastStoppedTimestamp: this.lastStoppedTimestamp
        ? this.lastStoppedTimestamp.toProtobufJSON(options)
        : null,
      timeSpentInSec: this.timeSpentInSec,
      tenantCode: this.tenantCode,
      startedBy: this.startedBy,
      startDate: this.startDate ? this.startDate.toProtobufJSON(options) : null,
      endDate: this.endDate ? this.endDate.toProtobufJSON(options) : null,
      userGroupId: this.userGroupId
    };
  }
}
export module WorkspaceConfig {
  /**
   * Standard JavaScript object representation for WorkspaceConfig
   */
  export interface AsObject {
    id: string;
    template?: WorkspaceTemplate.AsObject;
    status: string;
    url: string;
    namespace: string;
    createdTimestamp?: googleProtobuf000.Timestamp.AsObject;
    accessControl?: haKodeproV1001.AccessControl.AsObject;
    options?: WorkspaceOptions.AsObject;
    monitoring?: haKodeproV1002.WorkspaceMonitoring.AsObject;
    temporaryWorkspaceFor: string;
    lastStartedTimestamp?: googleProtobuf000.Timestamp.AsObject;
    lastStoppedTimestamp?: googleProtobuf000.Timestamp.AsObject;
    timeSpentInSec: number;
    tenantCode: string;
    startedBy: string;
    startDate?: googleProtobuf000.Timestamp.AsObject;
    endDate?: googleProtobuf000.Timestamp.AsObject;
    userGroupId: string;
  }

  /**
   * Protobuf JSON representation for WorkspaceConfig
   */
  export interface AsProtobufJSON {
    id: string;
    template: WorkspaceTemplate.AsProtobufJSON | null;
    status: string;
    url: string;
    namespace: string;
    createdTimestamp: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    accessControl: haKodeproV1001.AccessControl.AsProtobufJSON | null;
    options: WorkspaceOptions.AsProtobufJSON | null;
    monitoring: haKodeproV1002.WorkspaceMonitoring.AsProtobufJSON | null;
    temporaryWorkspaceFor: string;
    lastStartedTimestamp: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    lastStoppedTimestamp: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    timeSpentInSec: number;
    tenantCode: string;
    startedBy: string;
    startDate: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    endDate: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    userGroupId: string;
  }
}

/**
 * Message implementation for ha.kodepro.v1.WorkspaceOptions
 */
export class WorkspaceOptions implements GrpcMessage {
  static id = 'ha.kodepro.v1.WorkspaceOptions';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new WorkspaceOptions();
    WorkspaceOptions.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: WorkspaceOptions) {
    _instance.nodeType = _instance.nodeType || '';
    _instance.ide = _instance.ide || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: WorkspaceOptions,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.nodeType = _reader.readString();
          break;
        case 2:
          _instance.ide = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    WorkspaceOptions.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: WorkspaceOptions,
    _writer: BinaryWriter
  ) {
    if (_instance.nodeType) {
      _writer.writeString(1, _instance.nodeType);
    }
    if (_instance.ide) {
      _writer.writeString(2, _instance.ide);
    }
  }

  private _nodeType: string;
  private _ide: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of WorkspaceOptions to deeply clone from
   */
  constructor(_value?: RecursivePartial<WorkspaceOptions.AsObject>) {
    _value = _value || {};
    this.nodeType = _value.nodeType;
    this.ide = _value.ide;
    WorkspaceOptions.refineValues(this);
  }
  get nodeType(): string {
    return this._nodeType;
  }
  set nodeType(value: string) {
    this._nodeType = value;
  }
  get ide(): string {
    return this._ide;
  }
  set ide(value: string) {
    this._ide = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    WorkspaceOptions.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): WorkspaceOptions.AsObject {
    return {
      nodeType: this.nodeType,
      ide: this.ide
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): WorkspaceOptions.AsProtobufJSON {
    return {
      nodeType: this.nodeType,
      ide: this.ide
    };
  }
}
export module WorkspaceOptions {
  /**
   * Standard JavaScript object representation for WorkspaceOptions
   */
  export interface AsObject {
    nodeType: string;
    ide: string;
  }

  /**
   * Protobuf JSON representation for WorkspaceOptions
   */
  export interface AsProtobufJSON {
    nodeType: string;
    ide: string;
  }
}

/**
 * Message implementation for ha.kodepro.v1.WorkspaceTemplate
 */
export class WorkspaceTemplate implements GrpcMessage {
  static id = 'ha.kodepro.v1.WorkspaceTemplate';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new WorkspaceTemplate();
    WorkspaceTemplate.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: WorkspaceTemplate) {
    _instance.name = _instance.name || '';
    _instance.spec = _instance.spec || undefined;
    _instance.projects = _instance.projects || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: WorkspaceTemplate,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.name = _reader.readString();
          break;
        case 2:
          _instance.spec = new WorkspaceTemplateSpec();
          _reader.readMessage(
            _instance.spec,
            WorkspaceTemplateSpec.deserializeBinaryFromReader
          );
          break;
        case 3:
          const messageInitializer3 = new WorkspaceProject();
          _reader.readMessage(
            messageInitializer3,
            WorkspaceProject.deserializeBinaryFromReader
          );
          (_instance.projects = _instance.projects || []).push(
            messageInitializer3
          );
          break;
        default:
          _reader.skipField();
      }
    }

    WorkspaceTemplate.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: WorkspaceTemplate,
    _writer: BinaryWriter
  ) {
    if (_instance.name) {
      _writer.writeString(1, _instance.name);
    }
    if (_instance.spec) {
      _writer.writeMessage(
        2,
        _instance.spec as any,
        WorkspaceTemplateSpec.serializeBinaryToWriter
      );
    }
    if (_instance.projects && _instance.projects.length) {
      _writer.writeRepeatedMessage(
        3,
        _instance.projects as any,
        WorkspaceProject.serializeBinaryToWriter
      );
    }
  }

  private _name: string;
  private _spec?: WorkspaceTemplateSpec;
  private _projects?: WorkspaceProject[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of WorkspaceTemplate to deeply clone from
   */
  constructor(_value?: RecursivePartial<WorkspaceTemplate.AsObject>) {
    _value = _value || {};
    this.name = _value.name;
    this.spec = _value.spec
      ? new WorkspaceTemplateSpec(_value.spec)
      : undefined;
    this.projects = (_value.projects || []).map(m => new WorkspaceProject(m));
    WorkspaceTemplate.refineValues(this);
  }
  get name(): string {
    return this._name;
  }
  set name(value: string) {
    this._name = value;
  }
  get spec(): WorkspaceTemplateSpec | undefined {
    return this._spec;
  }
  set spec(value: WorkspaceTemplateSpec | undefined) {
    this._spec = value;
  }
  get projects(): WorkspaceProject[] | undefined {
    return this._projects;
  }
  set projects(value: WorkspaceProject[] | undefined) {
    this._projects = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    WorkspaceTemplate.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): WorkspaceTemplate.AsObject {
    return {
      name: this.name,
      spec: this.spec ? this.spec.toObject() : undefined,
      projects: (this.projects || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): WorkspaceTemplate.AsProtobufJSON {
    return {
      name: this.name,
      spec: this.spec ? this.spec.toProtobufJSON(options) : null,
      projects: (this.projects || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module WorkspaceTemplate {
  /**
   * Standard JavaScript object representation for WorkspaceTemplate
   */
  export interface AsObject {
    name: string;
    spec?: WorkspaceTemplateSpec.AsObject;
    projects?: WorkspaceProject.AsObject[];
  }

  /**
   * Protobuf JSON representation for WorkspaceTemplate
   */
  export interface AsProtobufJSON {
    name: string;
    spec: WorkspaceTemplateSpec.AsProtobufJSON | null;
    projects: WorkspaceProject.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for ha.kodepro.v1.WorkspaceProject
 */
export class WorkspaceProject implements GrpcMessage {
  static id = 'ha.kodepro.v1.WorkspaceProject';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new WorkspaceProject();
    WorkspaceProject.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: WorkspaceProject) {
    _instance.name = _instance.name || '';
    _instance.source = _instance.source || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: WorkspaceProject,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.name = _reader.readString();
          break;
        case 2:
          _instance.source = new ProjectSource();
          _reader.readMessage(
            _instance.source,
            ProjectSource.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    WorkspaceProject.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: WorkspaceProject,
    _writer: BinaryWriter
  ) {
    if (_instance.name) {
      _writer.writeString(1, _instance.name);
    }
    if (_instance.source) {
      _writer.writeMessage(
        2,
        _instance.source as any,
        ProjectSource.serializeBinaryToWriter
      );
    }
  }

  private _name: string;
  private _source?: ProjectSource;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of WorkspaceProject to deeply clone from
   */
  constructor(_value?: RecursivePartial<WorkspaceProject.AsObject>) {
    _value = _value || {};
    this.name = _value.name;
    this.source = _value.source ? new ProjectSource(_value.source) : undefined;
    WorkspaceProject.refineValues(this);
  }
  get name(): string {
    return this._name;
  }
  set name(value: string) {
    this._name = value;
  }
  get source(): ProjectSource | undefined {
    return this._source;
  }
  set source(value: ProjectSource | undefined) {
    this._source = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    WorkspaceProject.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): WorkspaceProject.AsObject {
    return {
      name: this.name,
      source: this.source ? this.source.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): WorkspaceProject.AsProtobufJSON {
    return {
      name: this.name,
      source: this.source ? this.source.toProtobufJSON(options) : null
    };
  }
}
export module WorkspaceProject {
  /**
   * Standard JavaScript object representation for WorkspaceProject
   */
  export interface AsObject {
    name: string;
    source?: ProjectSource.AsObject;
  }

  /**
   * Protobuf JSON representation for WorkspaceProject
   */
  export interface AsProtobufJSON {
    name: string;
    source: ProjectSource.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for ha.kodepro.v1.ProjectSource
 */
export class ProjectSource implements GrpcMessage {
  static id = 'ha.kodepro.v1.ProjectSource';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ProjectSource();
    ProjectSource.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ProjectSource) {
    _instance.type = _instance.type || '';
    _instance.location = _instance.location || '';
    _instance.branch = _instance.branch || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ProjectSource,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.type = _reader.readString();
          break;
        case 2:
          _instance.location = _reader.readString();
          break;
        case 3:
          _instance.branch = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    ProjectSource.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ProjectSource,
    _writer: BinaryWriter
  ) {
    if (_instance.type) {
      _writer.writeString(1, _instance.type);
    }
    if (_instance.location) {
      _writer.writeString(2, _instance.location);
    }
    if (_instance.branch) {
      _writer.writeString(3, _instance.branch);
    }
  }

  private _type: string;
  private _location: string;
  private _branch: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ProjectSource to deeply clone from
   */
  constructor(_value?: RecursivePartial<ProjectSource.AsObject>) {
    _value = _value || {};
    this.type = _value.type;
    this.location = _value.location;
    this.branch = _value.branch;
    ProjectSource.refineValues(this);
  }
  get type(): string {
    return this._type;
  }
  set type(value: string) {
    this._type = value;
  }
  get location(): string {
    return this._location;
  }
  set location(value: string) {
    this._location = value;
  }
  get branch(): string {
    return this._branch;
  }
  set branch(value: string) {
    this._branch = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ProjectSource.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ProjectSource.AsObject {
    return {
      type: this.type,
      location: this.location,
      branch: this.branch
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ProjectSource.AsProtobufJSON {
    return {
      type: this.type,
      location: this.location,
      branch: this.branch
    };
  }
}
export module ProjectSource {
  /**
   * Standard JavaScript object representation for ProjectSource
   */
  export interface AsObject {
    type: string;
    location: string;
    branch: string;
  }

  /**
   * Protobuf JSON representation for ProjectSource
   */
  export interface AsProtobufJSON {
    type: string;
    location: string;
    branch: string;
  }
}

/**
 * Message implementation for ha.kodepro.v1.WorkspaceTemplateSpec
 */
export class WorkspaceTemplateSpec implements GrpcMessage {
  static id = 'ha.kodepro.v1.WorkspaceTemplateSpec';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new WorkspaceTemplateSpec();
    WorkspaceTemplateSpec.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: WorkspaceTemplateSpec) {
    _instance.ide = _instance.ide || undefined;
    _instance.components = _instance.components || [];
    _instance.tasks = _instance.tasks || [];
    _instance.env = _instance.env || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: WorkspaceTemplateSpec,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.ide = new IdeComponent();
          _reader.readMessage(
            _instance.ide,
            IdeComponent.deserializeBinaryFromReader
          );
          break;
        case 2:
          const messageInitializer2 = new WorkspaceComponent();
          _reader.readMessage(
            messageInitializer2,
            WorkspaceComponent.deserializeBinaryFromReader
          );
          (_instance.components = _instance.components || []).push(
            messageInitializer2
          );
          break;
        case 3:
          const messageInitializer3 = new WorkspaceTask();
          _reader.readMessage(
            messageInitializer3,
            WorkspaceTask.deserializeBinaryFromReader
          );
          (_instance.tasks = _instance.tasks || []).push(messageInitializer3);
          break;
        case 4:
          const messageInitializer4 = new EnvVar();
          _reader.readMessage(
            messageInitializer4,
            EnvVar.deserializeBinaryFromReader
          );
          (_instance.env = _instance.env || []).push(messageInitializer4);
          break;
        default:
          _reader.skipField();
      }
    }

    WorkspaceTemplateSpec.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: WorkspaceTemplateSpec,
    _writer: BinaryWriter
  ) {
    if (_instance.ide) {
      _writer.writeMessage(
        1,
        _instance.ide as any,
        IdeComponent.serializeBinaryToWriter
      );
    }
    if (_instance.components && _instance.components.length) {
      _writer.writeRepeatedMessage(
        2,
        _instance.components as any,
        WorkspaceComponent.serializeBinaryToWriter
      );
    }
    if (_instance.tasks && _instance.tasks.length) {
      _writer.writeRepeatedMessage(
        3,
        _instance.tasks as any,
        WorkspaceTask.serializeBinaryToWriter
      );
    }
    if (_instance.env && _instance.env.length) {
      _writer.writeRepeatedMessage(
        4,
        _instance.env as any,
        EnvVar.serializeBinaryToWriter
      );
    }
  }

  private _ide?: IdeComponent;
  private _components?: WorkspaceComponent[];
  private _tasks?: WorkspaceTask[];
  private _env?: EnvVar[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of WorkspaceTemplateSpec to deeply clone from
   */
  constructor(_value?: RecursivePartial<WorkspaceTemplateSpec.AsObject>) {
    _value = _value || {};
    this.ide = _value.ide ? new IdeComponent(_value.ide) : undefined;
    this.components = (_value.components || []).map(
      m => new WorkspaceComponent(m)
    );
    this.tasks = (_value.tasks || []).map(m => new WorkspaceTask(m));
    this.env = (_value.env || []).map(m => new EnvVar(m));
    WorkspaceTemplateSpec.refineValues(this);
  }
  get ide(): IdeComponent | undefined {
    return this._ide;
  }
  set ide(value: IdeComponent | undefined) {
    this._ide = value;
  }
  get components(): WorkspaceComponent[] | undefined {
    return this._components;
  }
  set components(value: WorkspaceComponent[] | undefined) {
    this._components = value;
  }
  get tasks(): WorkspaceTask[] | undefined {
    return this._tasks;
  }
  set tasks(value: WorkspaceTask[] | undefined) {
    this._tasks = value;
  }
  get env(): EnvVar[] | undefined {
    return this._env;
  }
  set env(value: EnvVar[] | undefined) {
    this._env = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    WorkspaceTemplateSpec.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): WorkspaceTemplateSpec.AsObject {
    return {
      ide: this.ide ? this.ide.toObject() : undefined,
      components: (this.components || []).map(m => m.toObject()),
      tasks: (this.tasks || []).map(m => m.toObject()),
      env: (this.env || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): WorkspaceTemplateSpec.AsProtobufJSON {
    return {
      ide: this.ide ? this.ide.toProtobufJSON(options) : null,
      components: (this.components || []).map(m => m.toProtobufJSON(options)),
      tasks: (this.tasks || []).map(m => m.toProtobufJSON(options)),
      env: (this.env || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module WorkspaceTemplateSpec {
  /**
   * Standard JavaScript object representation for WorkspaceTemplateSpec
   */
  export interface AsObject {
    ide?: IdeComponent.AsObject;
    components?: WorkspaceComponent.AsObject[];
    tasks?: WorkspaceTask.AsObject[];
    env?: EnvVar.AsObject[];
  }

  /**
   * Protobuf JSON representation for WorkspaceTemplateSpec
   */
  export interface AsProtobufJSON {
    ide: IdeComponent.AsProtobufJSON | null;
    components: WorkspaceComponent.AsProtobufJSON[] | null;
    tasks: WorkspaceTask.AsProtobufJSON[] | null;
    env: EnvVar.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for ha.kodepro.v1.IdeComponent
 */
export class IdeComponent implements GrpcMessage {
  static id = 'ha.kodepro.v1.IdeComponent';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new IdeComponent();
    IdeComponent.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: IdeComponent) {
    _instance.type = _instance.type || '';
    _instance.alias = _instance.alias || '';
    _instance.memoryLimit = _instance.memoryLimit || '';
    _instance.cpuLimit = _instance.cpuLimit || '';
    _instance.env = _instance.env || [];
    _instance.image = _instance.image || '';
    _instance.endpoints = _instance.endpoints || [];
    _instance.redirectPorts = _instance.redirectPorts || false;
    _instance.ephemeralLimit = _instance.ephemeralLimit || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: IdeComponent,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.type = _reader.readString();
          break;
        case 2:
          _instance.alias = _reader.readString();
          break;
        case 3:
          _instance.memoryLimit = _reader.readString();
          break;
        case 4:
          _instance.cpuLimit = _reader.readString();
          break;
        case 5:
          const messageInitializer5 = new EnvVar();
          _reader.readMessage(
            messageInitializer5,
            EnvVar.deserializeBinaryFromReader
          );
          (_instance.env = _instance.env || []).push(messageInitializer5);
          break;
        case 6:
          _instance.image = _reader.readString();
          break;
        case 7:
          const messageInitializer7 = new WorkspaceEndpoint();
          _reader.readMessage(
            messageInitializer7,
            WorkspaceEndpoint.deserializeBinaryFromReader
          );
          (_instance.endpoints = _instance.endpoints || []).push(
            messageInitializer7
          );
          break;
        case 8:
          _instance.redirectPorts = _reader.readBool();
          break;
        case 9:
          _instance.ephemeralLimit = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    IdeComponent.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: IdeComponent,
    _writer: BinaryWriter
  ) {
    if (_instance.type) {
      _writer.writeString(1, _instance.type);
    }
    if (_instance.alias) {
      _writer.writeString(2, _instance.alias);
    }
    if (_instance.memoryLimit) {
      _writer.writeString(3, _instance.memoryLimit);
    }
    if (_instance.cpuLimit) {
      _writer.writeString(4, _instance.cpuLimit);
    }
    if (_instance.env && _instance.env.length) {
      _writer.writeRepeatedMessage(
        5,
        _instance.env as any,
        EnvVar.serializeBinaryToWriter
      );
    }
    if (_instance.image) {
      _writer.writeString(6, _instance.image);
    }
    if (_instance.endpoints && _instance.endpoints.length) {
      _writer.writeRepeatedMessage(
        7,
        _instance.endpoints as any,
        WorkspaceEndpoint.serializeBinaryToWriter
      );
    }
    if (_instance.redirectPorts) {
      _writer.writeBool(8, _instance.redirectPorts);
    }
    if (_instance.ephemeralLimit) {
      _writer.writeString(9, _instance.ephemeralLimit);
    }
  }

  private _type: string;
  private _alias: string;
  private _memoryLimit: string;
  private _cpuLimit: string;
  private _env?: EnvVar[];
  private _image: string;
  private _endpoints?: WorkspaceEndpoint[];
  private _redirectPorts: boolean;
  private _ephemeralLimit: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of IdeComponent to deeply clone from
   */
  constructor(_value?: RecursivePartial<IdeComponent.AsObject>) {
    _value = _value || {};
    this.type = _value.type;
    this.alias = _value.alias;
    this.memoryLimit = _value.memoryLimit;
    this.cpuLimit = _value.cpuLimit;
    this.env = (_value.env || []).map(m => new EnvVar(m));
    this.image = _value.image;
    this.endpoints = (_value.endpoints || []).map(
      m => new WorkspaceEndpoint(m)
    );
    this.redirectPorts = _value.redirectPorts;
    this.ephemeralLimit = _value.ephemeralLimit;
    IdeComponent.refineValues(this);
  }
  get type(): string {
    return this._type;
  }
  set type(value: string) {
    this._type = value;
  }
  get alias(): string {
    return this._alias;
  }
  set alias(value: string) {
    this._alias = value;
  }
  get memoryLimit(): string {
    return this._memoryLimit;
  }
  set memoryLimit(value: string) {
    this._memoryLimit = value;
  }
  get cpuLimit(): string {
    return this._cpuLimit;
  }
  set cpuLimit(value: string) {
    this._cpuLimit = value;
  }
  get env(): EnvVar[] | undefined {
    return this._env;
  }
  set env(value: EnvVar[] | undefined) {
    this._env = value;
  }
  get image(): string {
    return this._image;
  }
  set image(value: string) {
    this._image = value;
  }
  get endpoints(): WorkspaceEndpoint[] | undefined {
    return this._endpoints;
  }
  set endpoints(value: WorkspaceEndpoint[] | undefined) {
    this._endpoints = value;
  }
  get redirectPorts(): boolean {
    return this._redirectPorts;
  }
  set redirectPorts(value: boolean) {
    this._redirectPorts = value;
  }
  get ephemeralLimit(): string {
    return this._ephemeralLimit;
  }
  set ephemeralLimit(value: string) {
    this._ephemeralLimit = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    IdeComponent.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): IdeComponent.AsObject {
    return {
      type: this.type,
      alias: this.alias,
      memoryLimit: this.memoryLimit,
      cpuLimit: this.cpuLimit,
      env: (this.env || []).map(m => m.toObject()),
      image: this.image,
      endpoints: (this.endpoints || []).map(m => m.toObject()),
      redirectPorts: this.redirectPorts,
      ephemeralLimit: this.ephemeralLimit
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): IdeComponent.AsProtobufJSON {
    return {
      type: this.type,
      alias: this.alias,
      memoryLimit: this.memoryLimit,
      cpuLimit: this.cpuLimit,
      env: (this.env || []).map(m => m.toProtobufJSON(options)),
      image: this.image,
      endpoints: (this.endpoints || []).map(m => m.toProtobufJSON(options)),
      redirectPorts: this.redirectPorts,
      ephemeralLimit: this.ephemeralLimit
    };
  }
}
export module IdeComponent {
  /**
   * Standard JavaScript object representation for IdeComponent
   */
  export interface AsObject {
    type: string;
    alias: string;
    memoryLimit: string;
    cpuLimit: string;
    env?: EnvVar.AsObject[];
    image: string;
    endpoints?: WorkspaceEndpoint.AsObject[];
    redirectPorts: boolean;
    ephemeralLimit: string;
  }

  /**
   * Protobuf JSON representation for IdeComponent
   */
  export interface AsProtobufJSON {
    type: string;
    alias: string;
    memoryLimit: string;
    cpuLimit: string;
    env: EnvVar.AsProtobufJSON[] | null;
    image: string;
    endpoints: WorkspaceEndpoint.AsProtobufJSON[] | null;
    redirectPorts: boolean;
    ephemeralLimit: string;
  }
}

/**
 * Message implementation for ha.kodepro.v1.WorkspaceComponent
 */
export class WorkspaceComponent implements GrpcMessage {
  static id = 'ha.kodepro.v1.WorkspaceComponent';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new WorkspaceComponent();
    WorkspaceComponent.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: WorkspaceComponent) {
    _instance.type = _instance.type || '';
    _instance.keyValues = _instance.keyValues || {};
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: WorkspaceComponent,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.type = _reader.readString();
          break;
        case 2:
          const msg_2 = {} as any;
          _reader.readMessage(
            msg_2,
            WorkspaceComponent.KeyValuesEntry.deserializeBinaryFromReader
          );
          _instance.keyValues = _instance.keyValues || {};
          _instance.keyValues[msg_2.key] = msg_2.value;
          break;
        default:
          _reader.skipField();
      }
    }

    WorkspaceComponent.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: WorkspaceComponent,
    _writer: BinaryWriter
  ) {
    if (_instance.type) {
      _writer.writeString(1, _instance.type);
    }
    if (!!_instance.keyValues) {
      const keys_2 = Object.keys(_instance.keyValues as any);

      if (keys_2.length) {
        const repeated_2 = keys_2
          .map(key => ({ key: key, value: (_instance.keyValues as any)[key] }))
          .reduce((r, v) => [...r, v], [] as any[]);

        _writer.writeRepeatedMessage(
          2,
          repeated_2,
          WorkspaceComponent.KeyValuesEntry.serializeBinaryToWriter
        );
      }
    }
  }

  private _type: string;
  private _keyValues: { [prop: string]: string };

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of WorkspaceComponent to deeply clone from
   */
  constructor(_value?: RecursivePartial<WorkspaceComponent.AsObject>) {
    _value = _value || {};
    this.type = _value.type;
    (this.keyValues = _value!.keyValues
      ? Object.keys(_value!.keyValues).reduce(
          (r, k) => ({ ...r, [k]: _value!.keyValues![k] }),
          {}
        )
      : {}),
      WorkspaceComponent.refineValues(this);
  }
  get type(): string {
    return this._type;
  }
  set type(value: string) {
    this._type = value;
  }
  get keyValues(): { [prop: string]: string } {
    return this._keyValues;
  }
  set keyValues(value: { [prop: string]: string }) {
    this._keyValues = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    WorkspaceComponent.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): WorkspaceComponent.AsObject {
    return {
      type: this.type,
      keyValues: this.keyValues
        ? Object.keys(this.keyValues).reduce(
            (r, k) => ({ ...r, [k]: this.keyValues![k] }),
            {}
          )
        : {}
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): WorkspaceComponent.AsProtobufJSON {
    return {
      type: this.type,
      keyValues: this.keyValues
        ? Object.keys(this.keyValues).reduce(
            (r, k) => ({ ...r, [k]: this.keyValues![k] }),
            {}
          )
        : {}
    };
  }
}
export module WorkspaceComponent {
  /**
   * Standard JavaScript object representation for WorkspaceComponent
   */
  export interface AsObject {
    type: string;
    keyValues: { [prop: string]: string };
  }

  /**
   * Protobuf JSON representation for WorkspaceComponent
   */
  export interface AsProtobufJSON {
    type: string;
    keyValues: { [prop: string]: string };
  }

  /**
   * Message implementation for ha.kodepro.v1.WorkspaceComponent.KeyValuesEntry
   */
  export class KeyValuesEntry implements GrpcMessage {
    static id = 'ha.kodepro.v1.WorkspaceComponent.KeyValuesEntry';

    /**
     * Deserialize binary data to message
     * @param instance message instance
     */
    static deserializeBinary(bytes: ByteSource) {
      const instance = new KeyValuesEntry();
      KeyValuesEntry.deserializeBinaryFromReader(
        instance,
        new BinaryReader(bytes)
      );
      return instance;
    }

    /**
     * Check all the properties and set default protobuf values if necessary
     * @param _instance message instance
     */
    static refineValues(_instance: KeyValuesEntry) {
      _instance.key = _instance.key || '';
      _instance.value = _instance.value || '';
    }

    /**
     * Deserializes / reads binary message into message instance using provided binary reader
     * @param _instance message instance
     * @param _reader binary reader instance
     */
    static deserializeBinaryFromReader(
      _instance: KeyValuesEntry,
      _reader: BinaryReader
    ) {
      while (_reader.nextField()) {
        if (_reader.isEndGroup()) break;

        switch (_reader.getFieldNumber()) {
          case 1:
            _instance.key = _reader.readString();
            break;
          case 2:
            _instance.value = _reader.readString();
            break;
          default:
            _reader.skipField();
        }
      }

      KeyValuesEntry.refineValues(_instance);
    }

    /**
     * Serializes a message to binary format using provided binary reader
     * @param _instance message instance
     * @param _writer binary writer instance
     */
    static serializeBinaryToWriter(
      _instance: KeyValuesEntry,
      _writer: BinaryWriter
    ) {
      if (_instance.key) {
        _writer.writeString(1, _instance.key);
      }
      if (_instance.value) {
        _writer.writeString(2, _instance.value);
      }
    }

    private _key: string;
    private _value: string;

    /**
     * Message constructor. Initializes the properties and applies default Protobuf values if necessary
     * @param _value initial values object or instance of KeyValuesEntry to deeply clone from
     */
    constructor(_value?: RecursivePartial<KeyValuesEntry.AsObject>) {
      _value = _value || {};
      this.key = _value.key;
      this.value = _value.value;
      KeyValuesEntry.refineValues(this);
    }
    get key(): string {
      return this._key;
    }
    set key(value: string) {
      this._key = value;
    }
    get value(): string {
      return this._value;
    }
    set value(value: string) {
      this._value = value;
    }

    /**
     * Serialize message to binary data
     * @param instance message instance
     */
    serializeBinary() {
      const writer = new BinaryWriter();
      KeyValuesEntry.serializeBinaryToWriter(this, writer);
      return writer.getResultBuffer();
    }

    /**
     * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
     */
    toObject(): KeyValuesEntry.AsObject {
      return {
        key: this.key,
        value: this.value
      };
    }

    /**
     * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
     */
    toJSON() {
      return this.toObject();
    }

    /**
     * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
     * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
     * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
     */
    toProtobufJSON(
      // @ts-ignore
      options?: ToProtobufJSONOptions
    ): KeyValuesEntry.AsProtobufJSON {
      return {
        key: this.key,
        value: this.value
      };
    }
  }
  export module KeyValuesEntry {
    /**
     * Standard JavaScript object representation for KeyValuesEntry
     */
    export interface AsObject {
      key: string;
      value: string;
    }

    /**
     * Protobuf JSON representation for KeyValuesEntry
     */
    export interface AsProtobufJSON {
      key: string;
      value: string;
    }
  }
}

/**
 * Message implementation for ha.kodepro.v1.WorkspaceTask
 */
export class WorkspaceTask implements GrpcMessage {
  static id = 'ha.kodepro.v1.WorkspaceTask';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new WorkspaceTask();
    WorkspaceTask.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: WorkspaceTask) {
    _instance.name = _instance.name || '';
    _instance.type = _instance.type || '';
    _instance.command = _instance.command || '';
    _instance.workdir = _instance.workdir || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: WorkspaceTask,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.name = _reader.readString();
          break;
        case 2:
          _instance.type = _reader.readString();
          break;
        case 3:
          _instance.command = _reader.readString();
          break;
        case 4:
          _instance.workdir = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    WorkspaceTask.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: WorkspaceTask,
    _writer: BinaryWriter
  ) {
    if (_instance.name) {
      _writer.writeString(1, _instance.name);
    }
    if (_instance.type) {
      _writer.writeString(2, _instance.type);
    }
    if (_instance.command) {
      _writer.writeString(3, _instance.command);
    }
    if (_instance.workdir) {
      _writer.writeString(4, _instance.workdir);
    }
  }

  private _name: string;
  private _type: string;
  private _command: string;
  private _workdir: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of WorkspaceTask to deeply clone from
   */
  constructor(_value?: RecursivePartial<WorkspaceTask.AsObject>) {
    _value = _value || {};
    this.name = _value.name;
    this.type = _value.type;
    this.command = _value.command;
    this.workdir = _value.workdir;
    WorkspaceTask.refineValues(this);
  }
  get name(): string {
    return this._name;
  }
  set name(value: string) {
    this._name = value;
  }
  get type(): string {
    return this._type;
  }
  set type(value: string) {
    this._type = value;
  }
  get command(): string {
    return this._command;
  }
  set command(value: string) {
    this._command = value;
  }
  get workdir(): string {
    return this._workdir;
  }
  set workdir(value: string) {
    this._workdir = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    WorkspaceTask.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): WorkspaceTask.AsObject {
    return {
      name: this.name,
      type: this.type,
      command: this.command,
      workdir: this.workdir
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): WorkspaceTask.AsProtobufJSON {
    return {
      name: this.name,
      type: this.type,
      command: this.command,
      workdir: this.workdir
    };
  }
}
export module WorkspaceTask {
  /**
   * Standard JavaScript object representation for WorkspaceTask
   */
  export interface AsObject {
    name: string;
    type: string;
    command: string;
    workdir: string;
  }

  /**
   * Protobuf JSON representation for WorkspaceTask
   */
  export interface AsProtobufJSON {
    name: string;
    type: string;
    command: string;
    workdir: string;
  }
}

/**
 * Message implementation for ha.kodepro.v1.EnvVar
 */
export class EnvVar implements GrpcMessage {
  static id = 'ha.kodepro.v1.EnvVar';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new EnvVar();
    EnvVar.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: EnvVar) {
    _instance.key = _instance.key || '';
    _instance.val = _instance.val || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(_instance: EnvVar, _reader: BinaryReader) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.key = _reader.readString();
          break;
        case 2:
          _instance.val = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    EnvVar.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: EnvVar, _writer: BinaryWriter) {
    if (_instance.key) {
      _writer.writeString(1, _instance.key);
    }
    if (_instance.val) {
      _writer.writeString(2, _instance.val);
    }
  }

  private _key: string;
  private _val: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of EnvVar to deeply clone from
   */
  constructor(_value?: RecursivePartial<EnvVar.AsObject>) {
    _value = _value || {};
    this.key = _value.key;
    this.val = _value.val;
    EnvVar.refineValues(this);
  }
  get key(): string {
    return this._key;
  }
  set key(value: string) {
    this._key = value;
  }
  get val(): string {
    return this._val;
  }
  set val(value: string) {
    this._val = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    EnvVar.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): EnvVar.AsObject {
    return {
      key: this.key,
      val: this.val
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): EnvVar.AsProtobufJSON {
    return {
      key: this.key,
      val: this.val
    };
  }
}
export module EnvVar {
  /**
   * Standard JavaScript object representation for EnvVar
   */
  export interface AsObject {
    key: string;
    val: string;
  }

  /**
   * Protobuf JSON representation for EnvVar
   */
  export interface AsProtobufJSON {
    key: string;
    val: string;
  }
}

/**
 * Message implementation for ha.kodepro.v1.WorkspaceEndpoint
 */
export class WorkspaceEndpoint implements GrpcMessage {
  static id = 'ha.kodepro.v1.WorkspaceEndpoint';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new WorkspaceEndpoint();
    WorkspaceEndpoint.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: WorkspaceEndpoint) {
    _instance.port = _instance.port || 0;
    _instance.isPublic = _instance.isPublic || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: WorkspaceEndpoint,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.port = _reader.readInt32();
          break;
        case 2:
          _instance.isPublic = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    WorkspaceEndpoint.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: WorkspaceEndpoint,
    _writer: BinaryWriter
  ) {
    if (_instance.port) {
      _writer.writeInt32(1, _instance.port);
    }
    if (_instance.isPublic) {
      _writer.writeBool(2, _instance.isPublic);
    }
  }

  private _port: number;
  private _isPublic: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of WorkspaceEndpoint to deeply clone from
   */
  constructor(_value?: RecursivePartial<WorkspaceEndpoint.AsObject>) {
    _value = _value || {};
    this.port = _value.port;
    this.isPublic = _value.isPublic;
    WorkspaceEndpoint.refineValues(this);
  }
  get port(): number {
    return this._port;
  }
  set port(value: number) {
    this._port = value;
  }
  get isPublic(): boolean {
    return this._isPublic;
  }
  set isPublic(value: boolean) {
    this._isPublic = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    WorkspaceEndpoint.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): WorkspaceEndpoint.AsObject {
    return {
      port: this.port,
      isPublic: this.isPublic
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): WorkspaceEndpoint.AsProtobufJSON {
    return {
      port: this.port,
      isPublic: this.isPublic
    };
  }
}
export module WorkspaceEndpoint {
  /**
   * Standard JavaScript object representation for WorkspaceEndpoint
   */
  export interface AsObject {
    port: number;
    isPublic: boolean;
  }

  /**
   * Protobuf JSON representation for WorkspaceEndpoint
   */
  export interface AsProtobufJSON {
    port: number;
    isPublic: boolean;
  }
}
