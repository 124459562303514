import { Observable, from } from 'rxjs';
import { Injectable } from '@angular/core';
import { Observer } from 'rxjs';
import Keycloak, { KeycloakConfig } from 'keycloak-js';
import { KeycloakLoginCheckResponse } from './keycloak.model';
import { environment } from 'environments/environment';

@Injectable()
export class KeycloakApiService {
  private keycloak: Keycloak;
  protected initObservable: Observable<boolean>;

  constructor() {
    const config : KeycloakConfig = {
      url : environment.IDP_HOST,
	    realm: environment.IDP_REALM,
	    clientId: environment.IDP_CLIENT
    }
    this.keycloak = new Keycloak(config);
  }

  init(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.keycloak
        .init({ onLoad: 'check-sso', checkLoginIframe: false })
        .then(async authenticated => {
          resolve(authenticated);
        })
        .catch(kcError => {
          let msg = 'An error happened during Keycloak initialization.';
          if (kcError) {
            const { error, error_description } = kcError;
            msg = msg.concat(
              `\nAdapter error details:\nError: ${error}\nDescription: ${error_description}`
            );
          }
          reject(msg);
        });
    });
  }

  login(keycloakOptions?: Keycloak.KeycloakLoginOptions): Observable<void> {
    let options = {};
    if (keycloakOptions) {
      options = keycloakOptions;
    }
    console.debug(options);
    const promise: Promise<void> = new Promise((resolve, reject) => {
      this.keycloak
        .login(options)
        .then(() => resolve())
        .catch(() => reject(`An error happened during the login.`));
    });
    return from(promise);
  }

  /**
   * Redirects to logout.
   *
   * @param redirectUri
   * Specifies the uri to redirect to after logout.
   * @returns
   * A void Promise if the logout was successful, cleaning also the userProfile.
   */
  logout(redirectUri?: string): Observable<void> {
    if (!this.keycloak.authenticated) {
      return new Observable<void>();
    }
    const promise: Promise<void> = new Promise((resolve, reject) => {
      const options: any = {
        redirectUri
      };

      this.keycloak
        .logout(options)
        .then(() => {
          resolve();
        })
        .catch(() => reject('An error happened during logout.'));
    });
    return from(promise);
  }

  /**
   * Check if user is logged in.
   *
   * @returns
   * A boolean that indicates if the user is logged in.
   */
  async isLoggedIn(): Promise<boolean> {
    try {
      if (!this.keycloak.authenticated) {
        return false;
      }
      await this.updateToken(20);
      return true;
    } catch (error) {
      return false;
    }
  }

  /**
   * Returns true if the token has less than minValidity seconds left before
   * it expires.
   *
   * @param minValidity
   * Seconds left. (minValidity) is optional. Default value is 0.
   * @returns
   * Boolean indicating if the token is expired.
   */
  isTokenExpired(minValidity: number = 0): boolean {
    return this.keycloak.isTokenExpired(minValidity);
  }

  /**
   * If the token expires within minValidity seconds the token is refreshed. If the
   * session status iframe is enabled, the session status is also checked.
   * Returns a promise telling if the token was refreshed or not. If the session is not active
   * anymore, the promise is rejected.
   *
   * @param minValidity
   * Seconds left. (minValidity is optional, if not specified 5 is used)
   * @returns
   * Promise with a boolean indicating if the token was succesfully updated.
   */
  updateToken(minValidity: number = 5): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      if (!this.keycloak) {
        reject('Keycloak Angular library is not initialized.');
        return;
      }

      this.keycloak
        .updateToken(minValidity)
        .then(refreshed => {
          resolve(refreshed);
        })
        .catch(() =>
          reject('Failed to refresh the token, or the session is expired')
        );
    });
  }

  getBearerToken(): Observable<string> {
    return new Observable((observer: Observer<string>) => {
      const keycloak = this.keycloak.updateToken(5);
      if (this.keycloak.token) {
        keycloak.then(_ => observer.next(this.keycloak.token ? this.keycloak.token : '' ));
        keycloak.catch(data => this.keycloak.login());
      } else {
        observer.next('fake-Token' as string);
      }
    });
  }

  getCurrentUser(): Observable<KeycloakLoginCheckResponse> {
    const keycloakObserver = new Observable(
      (observer: Observer<KeycloakLoginCheckResponse>) => {
        if (this.keycloak.authenticated && this.keycloak.subject) {
          return observer.next({
            loggedIn: true,
            idmId: this.keycloak.subject
          });
        } else {
          return observer.error('User is not authenticated.');
        }
      }
    );
    return keycloakObserver;
  }
}
