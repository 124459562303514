import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GrpcMessage } from '@ngx-grpc/common';
import { GrpcCoreModule, GrpcLoggerModule } from '@ngx-grpc/core';
import { GrpcWorkerClientModule } from '@ngx-grpc/worker-client';
import { environment } from 'environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './auth.guard';
import { KeycloakApiService } from './common/service/keycloak/keycloak-api.service';
import { KodeproGrpcService } from './common/service/kodepro/kodepro-grpc.service';
import { WorkspaceTrackerService } from './common/service/kodepro/workspace-tracker.service';



function initializeKeycloak(keycloak: KeycloakApiService): () => Promise<boolean> {
  return (): Promise<boolean> => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await keycloak.init());
      } catch (error) {
        reject(error);
      }
    });
  };
}


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    GrpcWorkerClientModule.forChild({
      worker: new Worker(new URL('./grpc.worker', import.meta.url), { type: 'module' }),
      settings: { host: environment.KODEPRO_HOST }
    }),
    GrpcCoreModule.forRoot(),
    GrpcLoggerModule.forRoot({
      settings: {
        requestMapper: (msg: GrpcMessage) => msg.toProtobufJSON(),
        responseMapper: (msg: GrpcMessage) => msg.toProtobufJSON(),
      },
    })
  ],
  providers:[
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakApiService,KodeproGrpcService,WorkspaceTrackerService]
    },
    KeycloakApiService,
    KodeproGrpcService,
    WorkspaceTrackerService,
    AuthGuard
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
