/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as haKodeproV1000 from '../../../ha/kodepro/v1/common.pb';
/**
 * Message implementation for ha.kodepro.v1.WorkspaceSnapshotRequest
 */
export class WorkspaceSnapshotRequest implements GrpcMessage {
  static id = 'ha.kodepro.v1.WorkspaceSnapshotRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new WorkspaceSnapshotRequest();
    WorkspaceSnapshotRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: WorkspaceSnapshotRequest) {
    _instance.workspaceId = _instance.workspaceId || '';
    _instance.snapshotId = _instance.snapshotId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: WorkspaceSnapshotRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.workspaceId = _reader.readString();
          break;
        case 2:
          _instance.snapshotId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    WorkspaceSnapshotRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: WorkspaceSnapshotRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.workspaceId) {
      _writer.writeString(1, _instance.workspaceId);
    }
    if (_instance.snapshotId) {
      _writer.writeString(2, _instance.snapshotId);
    }
  }

  private _workspaceId: string;
  private _snapshotId: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of WorkspaceSnapshotRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<WorkspaceSnapshotRequest.AsObject>) {
    _value = _value || {};
    this.workspaceId = _value.workspaceId;
    this.snapshotId = _value.snapshotId;
    WorkspaceSnapshotRequest.refineValues(this);
  }
  get workspaceId(): string {
    return this._workspaceId;
  }
  set workspaceId(value: string) {
    this._workspaceId = value;
  }
  get snapshotId(): string {
    return this._snapshotId;
  }
  set snapshotId(value: string) {
    this._snapshotId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    WorkspaceSnapshotRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): WorkspaceSnapshotRequest.AsObject {
    return {
      workspaceId: this.workspaceId,
      snapshotId: this.snapshotId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): WorkspaceSnapshotRequest.AsProtobufJSON {
    return {
      workspaceId: this.workspaceId,
      snapshotId: this.snapshotId
    };
  }
}
export module WorkspaceSnapshotRequest {
  /**
   * Standard JavaScript object representation for WorkspaceSnapshotRequest
   */
  export interface AsObject {
    workspaceId: string;
    snapshotId: string;
  }

  /**
   * Protobuf JSON representation for WorkspaceSnapshotRequest
   */
  export interface AsProtobufJSON {
    workspaceId: string;
    snapshotId: string;
  }
}

/**
 * Message implementation for ha.kodepro.v1.WorkspaceSnapshotResponse
 */
export class WorkspaceSnapshotResponse implements GrpcMessage {
  static id = 'ha.kodepro.v1.WorkspaceSnapshotResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new WorkspaceSnapshotResponse();
    WorkspaceSnapshotResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: WorkspaceSnapshotResponse) {
    _instance.workspaceId = _instance.workspaceId || '';
    _instance.snapshotId = _instance.snapshotId || '';
    _instance.status = _instance.status || '';
    _instance.workspaceTimeSpentInSec = _instance.workspaceTimeSpentInSec || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: WorkspaceSnapshotResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.workspaceId = _reader.readString();
          break;
        case 2:
          _instance.snapshotId = _reader.readString();
          break;
        case 3:
          _instance.status = _reader.readString();
          break;
        case 4:
          _instance.workspaceTimeSpentInSec = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    WorkspaceSnapshotResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: WorkspaceSnapshotResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.workspaceId) {
      _writer.writeString(1, _instance.workspaceId);
    }
    if (_instance.snapshotId) {
      _writer.writeString(2, _instance.snapshotId);
    }
    if (_instance.status) {
      _writer.writeString(3, _instance.status);
    }
    if (_instance.workspaceTimeSpentInSec) {
      _writer.writeInt32(4, _instance.workspaceTimeSpentInSec);
    }
  }

  private _workspaceId: string;
  private _snapshotId: string;
  private _status: string;
  private _workspaceTimeSpentInSec: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of WorkspaceSnapshotResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<WorkspaceSnapshotResponse.AsObject>) {
    _value = _value || {};
    this.workspaceId = _value.workspaceId;
    this.snapshotId = _value.snapshotId;
    this.status = _value.status;
    this.workspaceTimeSpentInSec = _value.workspaceTimeSpentInSec;
    WorkspaceSnapshotResponse.refineValues(this);
  }
  get workspaceId(): string {
    return this._workspaceId;
  }
  set workspaceId(value: string) {
    this._workspaceId = value;
  }
  get snapshotId(): string {
    return this._snapshotId;
  }
  set snapshotId(value: string) {
    this._snapshotId = value;
  }
  get status(): string {
    return this._status;
  }
  set status(value: string) {
    this._status = value;
  }
  get workspaceTimeSpentInSec(): number {
    return this._workspaceTimeSpentInSec;
  }
  set workspaceTimeSpentInSec(value: number) {
    this._workspaceTimeSpentInSec = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    WorkspaceSnapshotResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): WorkspaceSnapshotResponse.AsObject {
    return {
      workspaceId: this.workspaceId,
      snapshotId: this.snapshotId,
      status: this.status,
      workspaceTimeSpentInSec: this.workspaceTimeSpentInSec
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): WorkspaceSnapshotResponse.AsProtobufJSON {
    return {
      workspaceId: this.workspaceId,
      snapshotId: this.snapshotId,
      status: this.status,
      workspaceTimeSpentInSec: this.workspaceTimeSpentInSec
    };
  }
}
export module WorkspaceSnapshotResponse {
  /**
   * Standard JavaScript object representation for WorkspaceSnapshotResponse
   */
  export interface AsObject {
    workspaceId: string;
    snapshotId: string;
    status: string;
    workspaceTimeSpentInSec: number;
  }

  /**
   * Protobuf JSON representation for WorkspaceSnapshotResponse
   */
  export interface AsProtobufJSON {
    workspaceId: string;
    snapshotId: string;
    status: string;
    workspaceTimeSpentInSec: number;
  }
}

/**
 * Message implementation for ha.kodepro.v1.WorkspaceGitDifferencesRequest
 */
export class WorkspaceGitDifferencesRequest implements GrpcMessage {
  static id = 'ha.kodepro.v1.WorkspaceGitDifferencesRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new WorkspaceGitDifferencesRequest();
    WorkspaceGitDifferencesRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: WorkspaceGitDifferencesRequest) {
    _instance.workspaceId = _instance.workspaceId || '';
    _instance.snapshotId = _instance.snapshotId || '';
    _instance.workspaceFolderPath = _instance.workspaceFolderPath || '';
    _instance.targetFolderZipPath = _instance.targetFolderZipPath || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: WorkspaceGitDifferencesRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.workspaceId = _reader.readString();
          break;
        case 2:
          _instance.snapshotId = _reader.readString();
          break;
        case 3:
          _instance.workspaceFolderPath = _reader.readString();
          break;
        case 4:
          _instance.targetFolderZipPath = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    WorkspaceGitDifferencesRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: WorkspaceGitDifferencesRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.workspaceId) {
      _writer.writeString(1, _instance.workspaceId);
    }
    if (_instance.snapshotId) {
      _writer.writeString(2, _instance.snapshotId);
    }
    if (_instance.workspaceFolderPath) {
      _writer.writeString(3, _instance.workspaceFolderPath);
    }
    if (_instance.targetFolderZipPath) {
      _writer.writeString(4, _instance.targetFolderZipPath);
    }
  }

  private _workspaceId: string;
  private _snapshotId: string;
  private _workspaceFolderPath: string;
  private _targetFolderZipPath: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of WorkspaceGitDifferencesRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<WorkspaceGitDifferencesRequest.AsObject>
  ) {
    _value = _value || {};
    this.workspaceId = _value.workspaceId;
    this.snapshotId = _value.snapshotId;
    this.workspaceFolderPath = _value.workspaceFolderPath;
    this.targetFolderZipPath = _value.targetFolderZipPath;
    WorkspaceGitDifferencesRequest.refineValues(this);
  }
  get workspaceId(): string {
    return this._workspaceId;
  }
  set workspaceId(value: string) {
    this._workspaceId = value;
  }
  get snapshotId(): string {
    return this._snapshotId;
  }
  set snapshotId(value: string) {
    this._snapshotId = value;
  }
  get workspaceFolderPath(): string {
    return this._workspaceFolderPath;
  }
  set workspaceFolderPath(value: string) {
    this._workspaceFolderPath = value;
  }
  get targetFolderZipPath(): string {
    return this._targetFolderZipPath;
  }
  set targetFolderZipPath(value: string) {
    this._targetFolderZipPath = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    WorkspaceGitDifferencesRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): WorkspaceGitDifferencesRequest.AsObject {
    return {
      workspaceId: this.workspaceId,
      snapshotId: this.snapshotId,
      workspaceFolderPath: this.workspaceFolderPath,
      targetFolderZipPath: this.targetFolderZipPath
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): WorkspaceGitDifferencesRequest.AsProtobufJSON {
    return {
      workspaceId: this.workspaceId,
      snapshotId: this.snapshotId,
      workspaceFolderPath: this.workspaceFolderPath,
      targetFolderZipPath: this.targetFolderZipPath
    };
  }
}
export module WorkspaceGitDifferencesRequest {
  /**
   * Standard JavaScript object representation for WorkspaceGitDifferencesRequest
   */
  export interface AsObject {
    workspaceId: string;
    snapshotId: string;
    workspaceFolderPath: string;
    targetFolderZipPath: string;
  }

  /**
   * Protobuf JSON representation for WorkspaceGitDifferencesRequest
   */
  export interface AsProtobufJSON {
    workspaceId: string;
    snapshotId: string;
    workspaceFolderPath: string;
    targetFolderZipPath: string;
  }
}

/**
 * Message implementation for ha.kodepro.v1.WorkspaceGitDifferencesResponse
 */
export class WorkspaceGitDifferencesResponse implements GrpcMessage {
  static id = 'ha.kodepro.v1.WorkspaceGitDifferencesResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new WorkspaceGitDifferencesResponse();
    WorkspaceGitDifferencesResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: WorkspaceGitDifferencesResponse) {
    _instance.workspaceId = _instance.workspaceId || '';
    _instance.snapshotId = _instance.snapshotId || '';
    _instance.workspaceFolderPath = _instance.workspaceFolderPath || '';
    _instance.targetFolderZipPath = _instance.targetFolderZipPath || '';
    _instance.workspaceDiff = _instance.workspaceDiff || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: WorkspaceGitDifferencesResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.workspaceId = _reader.readString();
          break;
        case 2:
          _instance.snapshotId = _reader.readString();
          break;
        case 3:
          _instance.workspaceFolderPath = _reader.readString();
          break;
        case 4:
          _instance.targetFolderZipPath = _reader.readString();
          break;
        case 5:
          _instance.workspaceDiff = new WorkspaceGitDifferences();
          _reader.readMessage(
            _instance.workspaceDiff,
            WorkspaceGitDifferences.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    WorkspaceGitDifferencesResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: WorkspaceGitDifferencesResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.workspaceId) {
      _writer.writeString(1, _instance.workspaceId);
    }
    if (_instance.snapshotId) {
      _writer.writeString(2, _instance.snapshotId);
    }
    if (_instance.workspaceFolderPath) {
      _writer.writeString(3, _instance.workspaceFolderPath);
    }
    if (_instance.targetFolderZipPath) {
      _writer.writeString(4, _instance.targetFolderZipPath);
    }
    if (_instance.workspaceDiff) {
      _writer.writeMessage(
        5,
        _instance.workspaceDiff as any,
        WorkspaceGitDifferences.serializeBinaryToWriter
      );
    }
  }

  private _workspaceId: string;
  private _snapshotId: string;
  private _workspaceFolderPath: string;
  private _targetFolderZipPath: string;
  private _workspaceDiff?: WorkspaceGitDifferences;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of WorkspaceGitDifferencesResponse to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<WorkspaceGitDifferencesResponse.AsObject>
  ) {
    _value = _value || {};
    this.workspaceId = _value.workspaceId;
    this.snapshotId = _value.snapshotId;
    this.workspaceFolderPath = _value.workspaceFolderPath;
    this.targetFolderZipPath = _value.targetFolderZipPath;
    this.workspaceDiff = _value.workspaceDiff
      ? new WorkspaceGitDifferences(_value.workspaceDiff)
      : undefined;
    WorkspaceGitDifferencesResponse.refineValues(this);
  }
  get workspaceId(): string {
    return this._workspaceId;
  }
  set workspaceId(value: string) {
    this._workspaceId = value;
  }
  get snapshotId(): string {
    return this._snapshotId;
  }
  set snapshotId(value: string) {
    this._snapshotId = value;
  }
  get workspaceFolderPath(): string {
    return this._workspaceFolderPath;
  }
  set workspaceFolderPath(value: string) {
    this._workspaceFolderPath = value;
  }
  get targetFolderZipPath(): string {
    return this._targetFolderZipPath;
  }
  set targetFolderZipPath(value: string) {
    this._targetFolderZipPath = value;
  }
  get workspaceDiff(): WorkspaceGitDifferences | undefined {
    return this._workspaceDiff;
  }
  set workspaceDiff(value: WorkspaceGitDifferences | undefined) {
    this._workspaceDiff = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    WorkspaceGitDifferencesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): WorkspaceGitDifferencesResponse.AsObject {
    return {
      workspaceId: this.workspaceId,
      snapshotId: this.snapshotId,
      workspaceFolderPath: this.workspaceFolderPath,
      targetFolderZipPath: this.targetFolderZipPath,
      workspaceDiff: this.workspaceDiff
        ? this.workspaceDiff.toObject()
        : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): WorkspaceGitDifferencesResponse.AsProtobufJSON {
    return {
      workspaceId: this.workspaceId,
      snapshotId: this.snapshotId,
      workspaceFolderPath: this.workspaceFolderPath,
      targetFolderZipPath: this.targetFolderZipPath,
      workspaceDiff: this.workspaceDiff
        ? this.workspaceDiff.toProtobufJSON(options)
        : null
    };
  }
}
export module WorkspaceGitDifferencesResponse {
  /**
   * Standard JavaScript object representation for WorkspaceGitDifferencesResponse
   */
  export interface AsObject {
    workspaceId: string;
    snapshotId: string;
    workspaceFolderPath: string;
    targetFolderZipPath: string;
    workspaceDiff?: WorkspaceGitDifferences.AsObject;
  }

  /**
   * Protobuf JSON representation for WorkspaceGitDifferencesResponse
   */
  export interface AsProtobufJSON {
    workspaceId: string;
    snapshotId: string;
    workspaceFolderPath: string;
    targetFolderZipPath: string;
    workspaceDiff: WorkspaceGitDifferences.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for ha.kodepro.v1.WorkspaceGitDifferences
 */
export class WorkspaceGitDifferences implements GrpcMessage {
  static id = 'ha.kodepro.v1.WorkspaceGitDifferences';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new WorkspaceGitDifferences();
    WorkspaceGitDifferences.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: WorkspaceGitDifferences) {
    _instance.folderPath = _instance.folderPath || '';
    _instance.items = _instance.items || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: WorkspaceGitDifferences,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.folderPath = _reader.readString();
          break;
        case 2:
          const messageInitializer2 = new WorkspaceFile();
          _reader.readMessage(
            messageInitializer2,
            WorkspaceFile.deserializeBinaryFromReader
          );
          (_instance.items = _instance.items || []).push(messageInitializer2);
          break;
        default:
          _reader.skipField();
      }
    }

    WorkspaceGitDifferences.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: WorkspaceGitDifferences,
    _writer: BinaryWriter
  ) {
    if (_instance.folderPath) {
      _writer.writeString(1, _instance.folderPath);
    }
    if (_instance.items && _instance.items.length) {
      _writer.writeRepeatedMessage(
        2,
        _instance.items as any,
        WorkspaceFile.serializeBinaryToWriter
      );
    }
  }

  private _folderPath: string;
  private _items?: WorkspaceFile[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of WorkspaceGitDifferences to deeply clone from
   */
  constructor(_value?: RecursivePartial<WorkspaceGitDifferences.AsObject>) {
    _value = _value || {};
    this.folderPath = _value.folderPath;
    this.items = (_value.items || []).map(m => new WorkspaceFile(m));
    WorkspaceGitDifferences.refineValues(this);
  }
  get folderPath(): string {
    return this._folderPath;
  }
  set folderPath(value: string) {
    this._folderPath = value;
  }
  get items(): WorkspaceFile[] | undefined {
    return this._items;
  }
  set items(value: WorkspaceFile[] | undefined) {
    this._items = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    WorkspaceGitDifferences.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): WorkspaceGitDifferences.AsObject {
    return {
      folderPath: this.folderPath,
      items: (this.items || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): WorkspaceGitDifferences.AsProtobufJSON {
    return {
      folderPath: this.folderPath,
      items: (this.items || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module WorkspaceGitDifferences {
  /**
   * Standard JavaScript object representation for WorkspaceGitDifferences
   */
  export interface AsObject {
    folderPath: string;
    items?: WorkspaceFile.AsObject[];
  }

  /**
   * Protobuf JSON representation for WorkspaceGitDifferences
   */
  export interface AsProtobufJSON {
    folderPath: string;
    items: WorkspaceFile.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for ha.kodepro.v1.WorkspaceFile
 */
export class WorkspaceFile implements GrpcMessage {
  static id = 'ha.kodepro.v1.WorkspaceFile';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new WorkspaceFile();
    WorkspaceFile.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: WorkspaceFile) {
    _instance.filePath = _instance.filePath || '';
    _instance.fileDiff = _instance.fileDiff || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: WorkspaceFile,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.filePath = _reader.readString();
          break;
        case 2:
          _instance.fileDiff = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    WorkspaceFile.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: WorkspaceFile,
    _writer: BinaryWriter
  ) {
    if (_instance.filePath) {
      _writer.writeString(1, _instance.filePath);
    }
    if (_instance.fileDiff) {
      _writer.writeString(2, _instance.fileDiff);
    }
  }

  private _filePath: string;
  private _fileDiff: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of WorkspaceFile to deeply clone from
   */
  constructor(_value?: RecursivePartial<WorkspaceFile.AsObject>) {
    _value = _value || {};
    this.filePath = _value.filePath;
    this.fileDiff = _value.fileDiff;
    WorkspaceFile.refineValues(this);
  }
  get filePath(): string {
    return this._filePath;
  }
  set filePath(value: string) {
    this._filePath = value;
  }
  get fileDiff(): string {
    return this._fileDiff;
  }
  set fileDiff(value: string) {
    this._fileDiff = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    WorkspaceFile.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): WorkspaceFile.AsObject {
    return {
      filePath: this.filePath,
      fileDiff: this.fileDiff
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): WorkspaceFile.AsProtobufJSON {
    return {
      filePath: this.filePath,
      fileDiff: this.fileDiff
    };
  }
}
export module WorkspaceFile {
  /**
   * Standard JavaScript object representation for WorkspaceFile
   */
  export interface AsObject {
    filePath: string;
    fileDiff: string;
  }

  /**
   * Protobuf JSON representation for WorkspaceFile
   */
  export interface AsProtobufJSON {
    filePath: string;
    fileDiff: string;
  }
}

/**
 * Message implementation for ha.kodepro.v1.ReplaceFolderRequest
 */
export class ReplaceFolderRequest implements GrpcMessage {
  static id = 'ha.kodepro.v1.ReplaceFolderRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ReplaceFolderRequest();
    ReplaceFolderRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ReplaceFolderRequest) {
    _instance.workspaceId = _instance.workspaceId || '';
    _instance.workspaceFolderPath = _instance.workspaceFolderPath || '';
    _instance.targetFolderZipPath = _instance.targetFolderZipPath || '';
    _instance.workspaceSystemUser = _instance.workspaceSystemUser || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ReplaceFolderRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.workspaceId = _reader.readString();
          break;
        case 2:
          _instance.workspaceFolderPath = _reader.readString();
          break;
        case 3:
          _instance.targetFolderZipPath = _reader.readString();
          break;
        case 4:
          _instance.workspaceSystemUser = new haKodeproV1000.SystemUser();
          _reader.readMessage(
            _instance.workspaceSystemUser,
            haKodeproV1000.SystemUser.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    ReplaceFolderRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ReplaceFolderRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.workspaceId) {
      _writer.writeString(1, _instance.workspaceId);
    }
    if (_instance.workspaceFolderPath) {
      _writer.writeString(2, _instance.workspaceFolderPath);
    }
    if (_instance.targetFolderZipPath) {
      _writer.writeString(3, _instance.targetFolderZipPath);
    }
    if (_instance.workspaceSystemUser) {
      _writer.writeMessage(
        4,
        _instance.workspaceSystemUser as any,
        haKodeproV1000.SystemUser.serializeBinaryToWriter
      );
    }
  }

  private _workspaceId: string;
  private _workspaceFolderPath: string;
  private _targetFolderZipPath: string;
  private _workspaceSystemUser?: haKodeproV1000.SystemUser;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ReplaceFolderRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<ReplaceFolderRequest.AsObject>) {
    _value = _value || {};
    this.workspaceId = _value.workspaceId;
    this.workspaceFolderPath = _value.workspaceFolderPath;
    this.targetFolderZipPath = _value.targetFolderZipPath;
    this.workspaceSystemUser = _value.workspaceSystemUser
      ? new haKodeproV1000.SystemUser(_value.workspaceSystemUser)
      : undefined;
    ReplaceFolderRequest.refineValues(this);
  }
  get workspaceId(): string {
    return this._workspaceId;
  }
  set workspaceId(value: string) {
    this._workspaceId = value;
  }
  get workspaceFolderPath(): string {
    return this._workspaceFolderPath;
  }
  set workspaceFolderPath(value: string) {
    this._workspaceFolderPath = value;
  }
  get targetFolderZipPath(): string {
    return this._targetFolderZipPath;
  }
  set targetFolderZipPath(value: string) {
    this._targetFolderZipPath = value;
  }
  get workspaceSystemUser(): haKodeproV1000.SystemUser | undefined {
    return this._workspaceSystemUser;
  }
  set workspaceSystemUser(value: haKodeproV1000.SystemUser | undefined) {
    this._workspaceSystemUser = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ReplaceFolderRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ReplaceFolderRequest.AsObject {
    return {
      workspaceId: this.workspaceId,
      workspaceFolderPath: this.workspaceFolderPath,
      targetFolderZipPath: this.targetFolderZipPath,
      workspaceSystemUser: this.workspaceSystemUser
        ? this.workspaceSystemUser.toObject()
        : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ReplaceFolderRequest.AsProtobufJSON {
    return {
      workspaceId: this.workspaceId,
      workspaceFolderPath: this.workspaceFolderPath,
      targetFolderZipPath: this.targetFolderZipPath,
      workspaceSystemUser: this.workspaceSystemUser
        ? this.workspaceSystemUser.toProtobufJSON(options)
        : null
    };
  }
}
export module ReplaceFolderRequest {
  /**
   * Standard JavaScript object representation for ReplaceFolderRequest
   */
  export interface AsObject {
    workspaceId: string;
    workspaceFolderPath: string;
    targetFolderZipPath: string;
    workspaceSystemUser?: haKodeproV1000.SystemUser.AsObject;
  }

  /**
   * Protobuf JSON representation for ReplaceFolderRequest
   */
  export interface AsProtobufJSON {
    workspaceId: string;
    workspaceFolderPath: string;
    targetFolderZipPath: string;
    workspaceSystemUser: haKodeproV1000.SystemUser.AsProtobufJSON | null;
  }
}
