import { NgModule, inject } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';

const routes: Routes = [
  { path: 'workspace/:workspaceId',
  loadChildren:
    () => import('./components/web-ide/index')
      .then(m => m.WebIdeModule),
  canActivate: [() => inject(AuthGuard).canActivate()]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
