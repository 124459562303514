/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import * as haKodeproV1001 from '../../../ha/kodepro/v1/user-mgmt.pb';
import * as haKodeproV1002 from '../../../ha/kodepro/v1/ws-monitoring.pb';
import * as haKodeproV1003 from '../../../ha/kodepro/v1/common.pb';
import * as haKodeproV1004 from '../../../ha/kodepro/v1/ws-template.pb';
export enum StreamStatus {
  PENDING = 0,
  DONE = 1,
  ERROR = 2
}
/**
 * Message implementation for ha.kodepro.v1.Event
 */
export class Event implements GrpcMessage {
  static id = 'ha.kodepro.v1.Event';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new Event();
    Event.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: Event) {
    _instance.code = _instance.code || 0;
    _instance.description = _instance.description || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(_instance: Event, _reader: BinaryReader) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.code = _reader.readInt32();
          break;
        case 2:
          _instance.description = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    Event.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: Event, _writer: BinaryWriter) {
    if (_instance.code) {
      _writer.writeInt32(1, _instance.code);
    }
    if (_instance.description) {
      _writer.writeString(2, _instance.description);
    }
  }

  private _code: number;
  private _description: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of Event to deeply clone from
   */
  constructor(_value?: RecursivePartial<Event.AsObject>) {
    _value = _value || {};
    this.code = _value.code;
    this.description = _value.description;
    Event.refineValues(this);
  }
  get code(): number {
    return this._code;
  }
  set code(value: number) {
    this._code = value;
  }
  get description(): string {
    return this._description;
  }
  set description(value: string) {
    this._description = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    Event.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): Event.AsObject {
    return {
      code: this.code,
      description: this.description
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): Event.AsProtobufJSON {
    return {
      code: this.code,
      description: this.description
    };
  }
}
export module Event {
  /**
   * Standard JavaScript object representation for Event
   */
  export interface AsObject {
    code: number;
    description: string;
  }

  /**
   * Protobuf JSON representation for Event
   */
  export interface AsProtobufJSON {
    code: number;
    description: string;
  }
}

/**
 * Message implementation for ha.kodepro.v1.Stream
 */
export class Stream implements GrpcMessage {
  static id = 'ha.kodepro.v1.Stream';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new Stream();
    Stream.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: Stream) {
    _instance.count = _instance.count || 0;
    _instance.event = _instance.event || undefined;
    _instance.status = _instance.status || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(_instance: Stream, _reader: BinaryReader) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.count = _reader.readInt32();
          break;
        case 2:
          _instance.event = new Event();
          _reader.readMessage(
            _instance.event,
            Event.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.status = _reader.readEnum();
          break;
        default:
          _reader.skipField();
      }
    }

    Stream.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: Stream, _writer: BinaryWriter) {
    if (_instance.count) {
      _writer.writeInt32(1, _instance.count);
    }
    if (_instance.event) {
      _writer.writeMessage(
        2,
        _instance.event as any,
        Event.serializeBinaryToWriter
      );
    }
    if (_instance.status) {
      _writer.writeEnum(3, _instance.status);
    }
  }

  private _count: number;
  private _event?: Event;
  private _status: StreamStatus;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of Stream to deeply clone from
   */
  constructor(_value?: RecursivePartial<Stream.AsObject>) {
    _value = _value || {};
    this.count = _value.count;
    this.event = _value.event ? new Event(_value.event) : undefined;
    this.status = _value.status;
    Stream.refineValues(this);
  }
  get count(): number {
    return this._count;
  }
  set count(value: number) {
    this._count = value;
  }
  get event(): Event | undefined {
    return this._event;
  }
  set event(value: Event | undefined) {
    this._event = value;
  }
  get status(): StreamStatus {
    return this._status;
  }
  set status(value: StreamStatus) {
    this._status = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    Stream.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): Stream.AsObject {
    return {
      count: this.count,
      event: this.event ? this.event.toObject() : undefined,
      status: this.status
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): Stream.AsProtobufJSON {
    return {
      count: this.count,
      event: this.event ? this.event.toProtobufJSON(options) : null,
      status:
        StreamStatus[
          this.status === null || this.status === undefined ? 0 : this.status
        ]
    };
  }
}
export module Stream {
  /**
   * Standard JavaScript object representation for Stream
   */
  export interface AsObject {
    count: number;
    event?: Event.AsObject;
    status: StreamStatus;
  }

  /**
   * Protobuf JSON representation for Stream
   */
  export interface AsProtobufJSON {
    count: number;
    event: Event.AsProtobufJSON | null;
    status: string;
  }
}

/**
 * Message implementation for ha.kodepro.v1.CommandResponse
 */
export class CommandResponse implements GrpcMessage {
  static id = 'ha.kodepro.v1.CommandResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CommandResponse();
    CommandResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CommandResponse) {
    _instance.workspaceId = _instance.workspaceId || '';
    _instance.exec = _instance.exec || '';
    _instance.output = _instance.output || '';
    _instance.error = _instance.error || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CommandResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.workspaceId = _reader.readString();
          break;
        case 2:
          _instance.exec = _reader.readString();
          break;
        case 3:
          _instance.output = _reader.readString();
          break;
        case 4:
          _instance.error = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    CommandResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CommandResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.workspaceId) {
      _writer.writeString(1, _instance.workspaceId);
    }
    if (_instance.exec) {
      _writer.writeString(2, _instance.exec);
    }
    if (_instance.output) {
      _writer.writeString(3, _instance.output);
    }
    if (_instance.error) {
      _writer.writeString(4, _instance.error);
    }
  }

  private _workspaceId: string;
  private _exec: string;
  private _output: string;
  private _error: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CommandResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<CommandResponse.AsObject>) {
    _value = _value || {};
    this.workspaceId = _value.workspaceId;
    this.exec = _value.exec;
    this.output = _value.output;
    this.error = _value.error;
    CommandResponse.refineValues(this);
  }
  get workspaceId(): string {
    return this._workspaceId;
  }
  set workspaceId(value: string) {
    this._workspaceId = value;
  }
  get exec(): string {
    return this._exec;
  }
  set exec(value: string) {
    this._exec = value;
  }
  get output(): string {
    return this._output;
  }
  set output(value: string) {
    this._output = value;
  }
  get error(): string {
    return this._error;
  }
  set error(value: string) {
    this._error = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CommandResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CommandResponse.AsObject {
    return {
      workspaceId: this.workspaceId,
      exec: this.exec,
      output: this.output,
      error: this.error
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CommandResponse.AsProtobufJSON {
    return {
      workspaceId: this.workspaceId,
      exec: this.exec,
      output: this.output,
      error: this.error
    };
  }
}
export module CommandResponse {
  /**
   * Standard JavaScript object representation for CommandResponse
   */
  export interface AsObject {
    workspaceId: string;
    exec: string;
    output: string;
    error: string;
  }

  /**
   * Protobuf JSON representation for CommandResponse
   */
  export interface AsProtobufJSON {
    workspaceId: string;
    exec: string;
    output: string;
    error: string;
  }
}

/**
 * Message implementation for ha.kodepro.v1.WorkspaceResponse
 */
export class WorkspaceResponse implements GrpcMessage {
  static id = 'ha.kodepro.v1.WorkspaceResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new WorkspaceResponse();
    WorkspaceResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: WorkspaceResponse) {
    _instance.id = _instance.id || '';
    _instance.name = _instance.name || '';
    _instance.url = _instance.url || '';
    _instance.namespace = _instance.namespace || '';
    _instance.status = _instance.status || '';
    _instance.stream = _instance.stream || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: WorkspaceResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 2:
          _instance.name = _reader.readString();
          break;
        case 3:
          _instance.url = _reader.readString();
          break;
        case 4:
          _instance.namespace = _reader.readString();
          break;
        case 5:
          _instance.status = _reader.readString();
          break;
        case 6:
          _instance.stream = new Stream();
          _reader.readMessage(
            _instance.stream,
            Stream.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    WorkspaceResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: WorkspaceResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.name) {
      _writer.writeString(2, _instance.name);
    }
    if (_instance.url) {
      _writer.writeString(3, _instance.url);
    }
    if (_instance.namespace) {
      _writer.writeString(4, _instance.namespace);
    }
    if (_instance.status) {
      _writer.writeString(5, _instance.status);
    }
    if (_instance.stream) {
      _writer.writeMessage(
        6,
        _instance.stream as any,
        Stream.serializeBinaryToWriter
      );
    }
  }

  private _id: string;
  private _name: string;
  private _url: string;
  private _namespace: string;
  private _status: string;
  private _stream?: Stream;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of WorkspaceResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<WorkspaceResponse.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.name = _value.name;
    this.url = _value.url;
    this.namespace = _value.namespace;
    this.status = _value.status;
    this.stream = _value.stream ? new Stream(_value.stream) : undefined;
    WorkspaceResponse.refineValues(this);
  }
  get id(): string {
    return this._id;
  }
  set id(value: string) {
    this._id = value;
  }
  get name(): string {
    return this._name;
  }
  set name(value: string) {
    this._name = value;
  }
  get url(): string {
    return this._url;
  }
  set url(value: string) {
    this._url = value;
  }
  get namespace(): string {
    return this._namespace;
  }
  set namespace(value: string) {
    this._namespace = value;
  }
  get status(): string {
    return this._status;
  }
  set status(value: string) {
    this._status = value;
  }
  get stream(): Stream | undefined {
    return this._stream;
  }
  set stream(value: Stream | undefined) {
    this._stream = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    WorkspaceResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): WorkspaceResponse.AsObject {
    return {
      id: this.id,
      name: this.name,
      url: this.url,
      namespace: this.namespace,
      status: this.status,
      stream: this.stream ? this.stream.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): WorkspaceResponse.AsProtobufJSON {
    return {
      id: this.id,
      name: this.name,
      url: this.url,
      namespace: this.namespace,
      status: this.status,
      stream: this.stream ? this.stream.toProtobufJSON(options) : null
    };
  }
}
export module WorkspaceResponse {
  /**
   * Standard JavaScript object representation for WorkspaceResponse
   */
  export interface AsObject {
    id: string;
    name: string;
    url: string;
    namespace: string;
    status: string;
    stream?: Stream.AsObject;
  }

  /**
   * Protobuf JSON representation for WorkspaceResponse
   */
  export interface AsProtobufJSON {
    id: string;
    name: string;
    url: string;
    namespace: string;
    status: string;
    stream: Stream.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for ha.kodepro.v1.TriggerJobResponse
 */
export class TriggerJobResponse implements GrpcMessage {
  static id = 'ha.kodepro.v1.TriggerJobResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new TriggerJobResponse();
    TriggerJobResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: TriggerJobResponse) {
    _instance.status = _instance.status || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: TriggerJobResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.status = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    TriggerJobResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: TriggerJobResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.status) {
      _writer.writeString(1, _instance.status);
    }
  }

  private _status: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of TriggerJobResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<TriggerJobResponse.AsObject>) {
    _value = _value || {};
    this.status = _value.status;
    TriggerJobResponse.refineValues(this);
  }
  get status(): string {
    return this._status;
  }
  set status(value: string) {
    this._status = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    TriggerJobResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): TriggerJobResponse.AsObject {
    return {
      status: this.status
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): TriggerJobResponse.AsProtobufJSON {
    return {
      status: this.status
    };
  }
}
export module TriggerJobResponse {
  /**
   * Standard JavaScript object representation for TriggerJobResponse
   */
  export interface AsObject {
    status: string;
  }

  /**
   * Protobuf JSON representation for TriggerJobResponse
   */
  export interface AsProtobufJSON {
    status: string;
  }
}

/**
 * Message implementation for ha.kodepro.v1.WorkspaceListResponse
 */
export class WorkspaceListResponse implements GrpcMessage {
  static id = 'ha.kodepro.v1.WorkspaceListResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new WorkspaceListResponse();
    WorkspaceListResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: WorkspaceListResponse) {
    _instance.items = _instance.items || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: WorkspaceListResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new haKodeproV1004.WorkspaceConfig();
          _reader.readMessage(
            messageInitializer1,
            haKodeproV1004.WorkspaceConfig.deserializeBinaryFromReader
          );
          (_instance.items = _instance.items || []).push(messageInitializer1);
          break;
        default:
          _reader.skipField();
      }
    }

    WorkspaceListResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: WorkspaceListResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.items && _instance.items.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.items as any,
        haKodeproV1004.WorkspaceConfig.serializeBinaryToWriter
      );
    }
  }

  private _items?: haKodeproV1004.WorkspaceConfig[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of WorkspaceListResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<WorkspaceListResponse.AsObject>) {
    _value = _value || {};
    this.items = (_value.items || []).map(
      m => new haKodeproV1004.WorkspaceConfig(m)
    );
    WorkspaceListResponse.refineValues(this);
  }
  get items(): haKodeproV1004.WorkspaceConfig[] | undefined {
    return this._items;
  }
  set items(value: haKodeproV1004.WorkspaceConfig[] | undefined) {
    this._items = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    WorkspaceListResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): WorkspaceListResponse.AsObject {
    return {
      items: (this.items || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): WorkspaceListResponse.AsProtobufJSON {
    return {
      items: (this.items || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module WorkspaceListResponse {
  /**
   * Standard JavaScript object representation for WorkspaceListResponse
   */
  export interface AsObject {
    items?: haKodeproV1004.WorkspaceConfig.AsObject[];
  }

  /**
   * Protobuf JSON representation for WorkspaceListResponse
   */
  export interface AsProtobufJSON {
    items: haKodeproV1004.WorkspaceConfig.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for ha.kodepro.v1.AuthTokenResponse
 */
export class AuthTokenResponse implements GrpcMessage {
  static id = 'ha.kodepro.v1.AuthTokenResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AuthTokenResponse();
    AuthTokenResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AuthTokenResponse) {
    _instance.token = _instance.token || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AuthTokenResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.token = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    AuthTokenResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AuthTokenResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.token) {
      _writer.writeString(1, _instance.token);
    }
  }

  private _token: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AuthTokenResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<AuthTokenResponse.AsObject>) {
    _value = _value || {};
    this.token = _value.token;
    AuthTokenResponse.refineValues(this);
  }
  get token(): string {
    return this._token;
  }
  set token(value: string) {
    this._token = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AuthTokenResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AuthTokenResponse.AsObject {
    return {
      token: this.token
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AuthTokenResponse.AsProtobufJSON {
    return {
      token: this.token
    };
  }
}
export module AuthTokenResponse {
  /**
   * Standard JavaScript object representation for AuthTokenResponse
   */
  export interface AsObject {
    token: string;
  }

  /**
   * Protobuf JSON representation for AuthTokenResponse
   */
  export interface AsProtobufJSON {
    token: string;
  }
}

/**
 * Message implementation for ha.kodepro.v1.CreateTenantResponse
 */
export class CreateTenantResponse implements GrpcMessage {
  static id = 'ha.kodepro.v1.CreateTenantResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CreateTenantResponse();
    CreateTenantResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CreateTenantResponse) {
    _instance.status = _instance.status || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CreateTenantResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.status = new haKodeproV1003.GenericResponse();
          _reader.readMessage(
            _instance.status,
            haKodeproV1003.GenericResponse.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    CreateTenantResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CreateTenantResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.status) {
      _writer.writeMessage(
        1,
        _instance.status as any,
        haKodeproV1003.GenericResponse.serializeBinaryToWriter
      );
    }
  }

  private _status?: haKodeproV1003.GenericResponse;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CreateTenantResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<CreateTenantResponse.AsObject>) {
    _value = _value || {};
    this.status = _value.status
      ? new haKodeproV1003.GenericResponse(_value.status)
      : undefined;
    CreateTenantResponse.refineValues(this);
  }
  get status(): haKodeproV1003.GenericResponse | undefined {
    return this._status;
  }
  set status(value: haKodeproV1003.GenericResponse | undefined) {
    this._status = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CreateTenantResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CreateTenantResponse.AsObject {
    return {
      status: this.status ? this.status.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CreateTenantResponse.AsProtobufJSON {
    return {
      status: this.status ? this.status.toProtobufJSON(options) : null
    };
  }
}
export module CreateTenantResponse {
  /**
   * Standard JavaScript object representation for CreateTenantResponse
   */
  export interface AsObject {
    status?: haKodeproV1003.GenericResponse.AsObject;
  }

  /**
   * Protobuf JSON representation for CreateTenantResponse
   */
  export interface AsProtobufJSON {
    status: haKodeproV1003.GenericResponse.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for ha.kodepro.v1.UpdateTenantResponse
 */
export class UpdateTenantResponse implements GrpcMessage {
  static id = 'ha.kodepro.v1.UpdateTenantResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpdateTenantResponse();
    UpdateTenantResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpdateTenantResponse) {
    _instance.status = _instance.status || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UpdateTenantResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.status = new haKodeproV1003.GenericResponse();
          _reader.readMessage(
            _instance.status,
            haKodeproV1003.GenericResponse.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    UpdateTenantResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UpdateTenantResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.status) {
      _writer.writeMessage(
        1,
        _instance.status as any,
        haKodeproV1003.GenericResponse.serializeBinaryToWriter
      );
    }
  }

  private _status?: haKodeproV1003.GenericResponse;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpdateTenantResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<UpdateTenantResponse.AsObject>) {
    _value = _value || {};
    this.status = _value.status
      ? new haKodeproV1003.GenericResponse(_value.status)
      : undefined;
    UpdateTenantResponse.refineValues(this);
  }
  get status(): haKodeproV1003.GenericResponse | undefined {
    return this._status;
  }
  set status(value: haKodeproV1003.GenericResponse | undefined) {
    this._status = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpdateTenantResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpdateTenantResponse.AsObject {
    return {
      status: this.status ? this.status.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UpdateTenantResponse.AsProtobufJSON {
    return {
      status: this.status ? this.status.toProtobufJSON(options) : null
    };
  }
}
export module UpdateTenantResponse {
  /**
   * Standard JavaScript object representation for UpdateTenantResponse
   */
  export interface AsObject {
    status?: haKodeproV1003.GenericResponse.AsObject;
  }

  /**
   * Protobuf JSON representation for UpdateTenantResponse
   */
  export interface AsProtobufJSON {
    status: haKodeproV1003.GenericResponse.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for ha.kodepro.v1.CreateUserGroupResponse
 */
export class CreateUserGroupResponse implements GrpcMessage {
  static id = 'ha.kodepro.v1.CreateUserGroupResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CreateUserGroupResponse();
    CreateUserGroupResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CreateUserGroupResponse) {
    _instance.groupId = _instance.groupId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CreateUserGroupResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.groupId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    CreateUserGroupResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CreateUserGroupResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.groupId) {
      _writer.writeString(1, _instance.groupId);
    }
  }

  private _groupId: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CreateUserGroupResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<CreateUserGroupResponse.AsObject>) {
    _value = _value || {};
    this.groupId = _value.groupId;
    CreateUserGroupResponse.refineValues(this);
  }
  get groupId(): string {
    return this._groupId;
  }
  set groupId(value: string) {
    this._groupId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CreateUserGroupResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CreateUserGroupResponse.AsObject {
    return {
      groupId: this.groupId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CreateUserGroupResponse.AsProtobufJSON {
    return {
      groupId: this.groupId
    };
  }
}
export module CreateUserGroupResponse {
  /**
   * Standard JavaScript object representation for CreateUserGroupResponse
   */
  export interface AsObject {
    groupId: string;
  }

  /**
   * Protobuf JSON representation for CreateUserGroupResponse
   */
  export interface AsProtobufJSON {
    groupId: string;
  }
}

/**
 * Message implementation for ha.kodepro.v1.UpdateUserGroupResponse
 */
export class UpdateUserGroupResponse implements GrpcMessage {
  static id = 'ha.kodepro.v1.UpdateUserGroupResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpdateUserGroupResponse();
    UpdateUserGroupResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpdateUserGroupResponse) {
    _instance.status = _instance.status || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UpdateUserGroupResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.status = new haKodeproV1003.GenericResponse();
          _reader.readMessage(
            _instance.status,
            haKodeproV1003.GenericResponse.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    UpdateUserGroupResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UpdateUserGroupResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.status) {
      _writer.writeMessage(
        1,
        _instance.status as any,
        haKodeproV1003.GenericResponse.serializeBinaryToWriter
      );
    }
  }

  private _status?: haKodeproV1003.GenericResponse;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpdateUserGroupResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<UpdateUserGroupResponse.AsObject>) {
    _value = _value || {};
    this.status = _value.status
      ? new haKodeproV1003.GenericResponse(_value.status)
      : undefined;
    UpdateUserGroupResponse.refineValues(this);
  }
  get status(): haKodeproV1003.GenericResponse | undefined {
    return this._status;
  }
  set status(value: haKodeproV1003.GenericResponse | undefined) {
    this._status = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpdateUserGroupResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpdateUserGroupResponse.AsObject {
    return {
      status: this.status ? this.status.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UpdateUserGroupResponse.AsProtobufJSON {
    return {
      status: this.status ? this.status.toProtobufJSON(options) : null
    };
  }
}
export module UpdateUserGroupResponse {
  /**
   * Standard JavaScript object representation for UpdateUserGroupResponse
   */
  export interface AsObject {
    status?: haKodeproV1003.GenericResponse.AsObject;
  }

  /**
   * Protobuf JSON representation for UpdateUserGroupResponse
   */
  export interface AsProtobufJSON {
    status: haKodeproV1003.GenericResponse.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for ha.kodepro.v1.GetUserGroupResponse
 */
export class GetUserGroupResponse implements GrpcMessage {
  static id = 'ha.kodepro.v1.GetUserGroupResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetUserGroupResponse();
    GetUserGroupResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetUserGroupResponse) {
    _instance.id = _instance.id || '';
    _instance.name = _instance.name || '';
    _instance.tenantCode = _instance.tenantCode || '';
    _instance.members = _instance.members || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetUserGroupResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 2:
          _instance.name = _reader.readString();
          break;
        case 3:
          _instance.tenantCode = _reader.readString();
          break;
        case 4:
          const messageInitializer4 = new haKodeproV1001.UserRole();
          _reader.readMessage(
            messageInitializer4,
            haKodeproV1001.UserRole.deserializeBinaryFromReader
          );
          (_instance.members = _instance.members || []).push(
            messageInitializer4
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetUserGroupResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetUserGroupResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.name) {
      _writer.writeString(2, _instance.name);
    }
    if (_instance.tenantCode) {
      _writer.writeString(3, _instance.tenantCode);
    }
    if (_instance.members && _instance.members.length) {
      _writer.writeRepeatedMessage(
        4,
        _instance.members as any,
        haKodeproV1001.UserRole.serializeBinaryToWriter
      );
    }
  }

  private _id: string;
  private _name: string;
  private _tenantCode: string;
  private _members?: haKodeproV1001.UserRole[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetUserGroupResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetUserGroupResponse.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.name = _value.name;
    this.tenantCode = _value.tenantCode;
    this.members = (_value.members || []).map(
      m => new haKodeproV1001.UserRole(m)
    );
    GetUserGroupResponse.refineValues(this);
  }
  get id(): string {
    return this._id;
  }
  set id(value: string) {
    this._id = value;
  }
  get name(): string {
    return this._name;
  }
  set name(value: string) {
    this._name = value;
  }
  get tenantCode(): string {
    return this._tenantCode;
  }
  set tenantCode(value: string) {
    this._tenantCode = value;
  }
  get members(): haKodeproV1001.UserRole[] | undefined {
    return this._members;
  }
  set members(value: haKodeproV1001.UserRole[] | undefined) {
    this._members = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetUserGroupResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetUserGroupResponse.AsObject {
    return {
      id: this.id,
      name: this.name,
      tenantCode: this.tenantCode,
      members: (this.members || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetUserGroupResponse.AsProtobufJSON {
    return {
      id: this.id,
      name: this.name,
      tenantCode: this.tenantCode,
      members: (this.members || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module GetUserGroupResponse {
  /**
   * Standard JavaScript object representation for GetUserGroupResponse
   */
  export interface AsObject {
    id: string;
    name: string;
    tenantCode: string;
    members?: haKodeproV1001.UserRole.AsObject[];
  }

  /**
   * Protobuf JSON representation for GetUserGroupResponse
   */
  export interface AsProtobufJSON {
    id: string;
    name: string;
    tenantCode: string;
    members: haKodeproV1001.UserRole.AsProtobufJSON[] | null;
  }
}
