/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
export enum TenantStatus {
  TENANT_STATUS_UNSPECIFIED = 0,
  TENANT_STATUS_ACTIVE = 1,
  TENANT_STATUS_INACTIVE = 2
}
export enum ResponseStatus {
  RESPONSE_STATUS_UNSPECIFIED = 0,
  RESPONSE_STATUS_SUCCESS = 1,
  RESPONSE_STATUS_FAILURE = 2
}
/**
 * Message implementation for ha.kodepro.v1.PaginationRequest
 */
export class PaginationRequest implements GrpcMessage {
  static id = 'ha.kodepro.v1.PaginationRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new PaginationRequest();
    PaginationRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: PaginationRequest) {
    _instance.pageNumber = _instance.pageNumber || 0;
    _instance.pageSize = _instance.pageSize || 0;
    _instance.documentId = _instance.documentId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: PaginationRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.pageNumber = _reader.readInt32();
          break;
        case 2:
          _instance.pageSize = _reader.readInt32();
          break;
        case 3:
          _instance.documentId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    PaginationRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: PaginationRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.pageNumber) {
      _writer.writeInt32(1, _instance.pageNumber);
    }
    if (_instance.pageSize) {
      _writer.writeInt32(2, _instance.pageSize);
    }
    if (_instance.documentId) {
      _writer.writeString(3, _instance.documentId);
    }
  }

  private _pageNumber: number;
  private _pageSize: number;
  private _documentId: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of PaginationRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<PaginationRequest.AsObject>) {
    _value = _value || {};
    this.pageNumber = _value.pageNumber;
    this.pageSize = _value.pageSize;
    this.documentId = _value.documentId;
    PaginationRequest.refineValues(this);
  }
  get pageNumber(): number {
    return this._pageNumber;
  }
  set pageNumber(value: number) {
    this._pageNumber = value;
  }
  get pageSize(): number {
    return this._pageSize;
  }
  set pageSize(value: number) {
    this._pageSize = value;
  }
  get documentId(): string {
    return this._documentId;
  }
  set documentId(value: string) {
    this._documentId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    PaginationRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): PaginationRequest.AsObject {
    return {
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      documentId: this.documentId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): PaginationRequest.AsProtobufJSON {
    return {
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      documentId: this.documentId
    };
  }
}
export module PaginationRequest {
  /**
   * Standard JavaScript object representation for PaginationRequest
   */
  export interface AsObject {
    pageNumber: number;
    pageSize: number;
    documentId: string;
  }

  /**
   * Protobuf JSON representation for PaginationRequest
   */
  export interface AsProtobufJSON {
    pageNumber: number;
    pageSize: number;
    documentId: string;
  }
}

/**
 * Message implementation for ha.kodepro.v1.SystemUser
 */
export class SystemUser implements GrpcMessage {
  static id = 'ha.kodepro.v1.SystemUser';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new SystemUser();
    SystemUser.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: SystemUser) {
    _instance.uid = _instance.uid || 0;
    _instance.gid = _instance.gid || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: SystemUser,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.uid = _reader.readInt32();
          break;
        case 2:
          _instance.gid = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    SystemUser.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: SystemUser, _writer: BinaryWriter) {
    if (_instance.uid) {
      _writer.writeInt32(1, _instance.uid);
    }
    if (_instance.gid) {
      _writer.writeInt32(2, _instance.gid);
    }
  }

  private _uid: number;
  private _gid: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of SystemUser to deeply clone from
   */
  constructor(_value?: RecursivePartial<SystemUser.AsObject>) {
    _value = _value || {};
    this.uid = _value.uid;
    this.gid = _value.gid;
    SystemUser.refineValues(this);
  }
  get uid(): number {
    return this._uid;
  }
  set uid(value: number) {
    this._uid = value;
  }
  get gid(): number {
    return this._gid;
  }
  set gid(value: number) {
    this._gid = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    SystemUser.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): SystemUser.AsObject {
    return {
      uid: this.uid,
      gid: this.gid
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): SystemUser.AsProtobufJSON {
    return {
      uid: this.uid,
      gid: this.gid
    };
  }
}
export module SystemUser {
  /**
   * Standard JavaScript object representation for SystemUser
   */
  export interface AsObject {
    uid: number;
    gid: number;
  }

  /**
   * Protobuf JSON representation for SystemUser
   */
  export interface AsProtobufJSON {
    uid: number;
    gid: number;
  }
}

/**
 * Message implementation for ha.kodepro.v1.GenericResponse
 */
export class GenericResponse implements GrpcMessage {
  static id = 'ha.kodepro.v1.GenericResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GenericResponse();
    GenericResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GenericResponse) {
    _instance.status = _instance.status || 0;
    _instance.statusMessage = _instance.statusMessage || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GenericResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.status = _reader.readEnum();
          break;
        case 2:
          _instance.statusMessage = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GenericResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GenericResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.status) {
      _writer.writeEnum(1, _instance.status);
    }
    if (_instance.statusMessage) {
      _writer.writeString(2, _instance.statusMessage);
    }
  }

  private _status: ResponseStatus;
  private _statusMessage: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GenericResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GenericResponse.AsObject>) {
    _value = _value || {};
    this.status = _value.status;
    this.statusMessage = _value.statusMessage;
    GenericResponse.refineValues(this);
  }
  get status(): ResponseStatus {
    return this._status;
  }
  set status(value: ResponseStatus) {
    this._status = value;
  }
  get statusMessage(): string {
    return this._statusMessage;
  }
  set statusMessage(value: string) {
    this._statusMessage = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GenericResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GenericResponse.AsObject {
    return {
      status: this.status,
      statusMessage: this.statusMessage
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GenericResponse.AsProtobufJSON {
    return {
      status:
        ResponseStatus[
          this.status === null || this.status === undefined ? 0 : this.status
        ],
      statusMessage: this.statusMessage
    };
  }
}
export module GenericResponse {
  /**
   * Standard JavaScript object representation for GenericResponse
   */
  export interface AsObject {
    status: ResponseStatus;
    statusMessage: string;
  }

  /**
   * Protobuf JSON representation for GenericResponse
   */
  export interface AsProtobufJSON {
    status: string;
    statusMessage: string;
  }
}
