import { Injectable } from "@angular/core";
import ReconnectingWebSocket from "reconnecting-websocket";
import { WorkspaceStatus } from "./workspace-status";

@Injectable()
export class WorkspaceTrackerService {
  public startWorkspaceTracking(
    workspaceURL: URL,
    onWorkspaceStatusChange: Function
  ) {
    const webSocketConfigOptions = {
      maxReconnectionDelay: 30000,
      minReconnectionDelay: 5000,
      reconnectionDelayGrowFactor: 1.3,
      minUptime: 1000,
      connectionTimeout: 2000,
      maxRetries: 5,
      maxEnqueuedMessages: 50,
      startClosed: false,
      debug: false,
    };
    const rws = new ReconnectingWebSocket(
      `wss://${workspaceURL.hostname}`,
      [],
      webSocketConfigOptions
    );
    
    rws.addEventListener("close", (evt) => {
      console.debug("connection close", evt);
      if (evt.target._retryCount) {
        if (evt.target._retryCount == webSocketConfigOptions.maxRetries) {
          console.info(`!!!closed connection!!!`);
          onWorkspaceStatusChange(WorkspaceStatus.STOPPED,workspaceURL);
        } else {
          console.info(`!!!close connection retry count ${evt.target._retryCount}!!!`);
          onWorkspaceStatusChange(WorkspaceStatus.RECONNECTING,workspaceURL);
        }
      }
    });

    rws.addEventListener("open", (evt) => {
          onWorkspaceStatusChange(WorkspaceStatus.RUNNING, workspaceURL);
    });
  }
}
