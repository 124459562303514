import { Injectable } from '@angular/core';
import { KeycloakApiService } from './common/service/keycloak/keycloak-api.service';


@Injectable()
export class AuthGuard {

  constructor(
    private keycloak: KeycloakApiService,
  ) { }

  canActivate(
  ): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      try {
        const authenticated = await this.keycloak.isLoggedIn();
        if (!authenticated) {
          this.keycloak.login();
          return;
        }
        resolve(authenticated);
      } catch (error) {
        reject('An error happened during access validation. Details:' + error);
      }
    });

  }

}
